var rex_php_tags = new RegExp("<\\?php(?:[\\n\\r]|.)+?\\?>(?!\"|'')", "gim");
var rex_script_tags = new RegExp("<script(?:[\\n\\r]|.)+?\\/script>", "gim");
var rex_comments_tags = new RegExp("<!--(?:[\\n\\r]|.)+?-->", "gim");

// Récupération de la valeur de la constante NO_LAZYLOAD pour appliquer le paramètre aux swiper
var isLazyLoad = is_lazy_load ? false : true;

// Strips php contents /!\ THIS DELETES PHP TAGS AND EVERYTHING IN BETWEEN /!\
function stripPhpContents(str) {
    return str.replace(rex_php_tags, "");
}

// Strips js contents /!\ THIS DELETES SCRIPT TAGS AND EVERYTHING IN BETWEEN /!\
function stripJSContents(str) {
    return str.replace(rex_script_tags, "");
}

// Strips html comments /!\ THIS DELETES COMMENTS TAGS AND EVERYTHING IN BETWEEN /!\
function stripHTMLComments(str) {
    return str.replace(rex_comments_tags, "");
}

function stripCombo(str) {
    var output = stripHTMLComments(str);

    output = stripJSContents(output);
    output = stripPhpContents(output);

    return output;
}

var elmt;
var loader;
var prev_display;
// Shows button's underlying loader and triggers defined action
function waitingProcess(loader, action, id_form) {
    var elmt = $(this),
        loader = $(loader);

    prev_display = elmt.css("display");

    if (!loader.parent().hasClass("loading")) {
        loader.parent().addClass("loading");

        switch (action) {
            case 0:
                window.setTimeout(
                    ajax_checkAllForm,
                    1000,
                    tabAuthTop,
                    1,
                    path_relative_root
                );
                break;

            case 1:
                window.setTimeout(
                    ajax_checkAllForm,
                    1000,
                    tabLostPwdTop,
                    1,
                    path_relative_root
                );
                break;

            case 2:
                window.setTimeout(ajaxDeconnect, 1000, path_relative_root);
                break;

            case 3:
                window.setTimeout(function() {
                    checkAllForm(tabError, 0, path_relative_root, id_form);
                    loader.parent().removeClass("loading");
                }, 1000); // attention ne pas modifier le temps 1000 pour que le intelinput ai le temps de bien remplir les données

            case 4:
                window.setTimeout(
                    ajax_checkAllForm,
                    1000,
                    tabAuth,
                    1,
                    path_relative_root
                );
                break;

            case 5:
                window.setTimeout(
                    ajax_checkAllForm,
                    1000,
                    tabLostPwd,
                    1,
                    path_relative_root
                );
                break;

            case 6:
                window.setTimeout(checkFormParrainage, 1000);
                break;

            case 7:
                if (
                    checkAllForm(
                        tabErrorGrattez,
                        0,
                        path_relative_root,
                        id_form
                    )
                ) {
                    window.setTimeout(processGrattezGagnez(), 1000);
                }
                break;
        }
    }
}

function hideLoader() {
    if (loader) {
        loader.css("display", "none");
        loader
            .removeAttr("style")
            .parent()
            .removeClass("loading");
    }
    if (elmt) {
        elmt.css("display", prev_display);
    }
}

// Checks any form
function ajax_checkAllForm(
    tabError,
    blockMenuTopConnexionInfobulle,
    path_relative_root
) {
    if (checkAllForm(tabError, blockMenuTopConnexionInfobulle)) {
        if (tabError[0][1] && tabError[0][1] == "clientMailAuthTop") {
            $.ajax({
                url: path_relative_root + "ajax_login_allowed.php",
                type: "get",
                data: {
                    mail: document.getElementById(tabError[0][1]).value,
                    mdp: document.getElementById(tabError[1][1]).value,
                    is_top: true,
                },
                success: function(res) {
                    var url, index_deb, index_fin, provenance;

                    if (res[0] !== "0") {
                        url = window.location.pathname;
                        index_deb = url.lastIndexOf("/");
                        index_fin = url.lastIndexOf(".");
                        provenance = url.substring(index_deb + 1, index_fin);

                        if (provenance === "connexion") {
                            window.location.replace(
                                path_relative_root + create_link("client_index")
                            );
                        } else if (provenance === "identification") {
                            window.location.replace(
                                path_relative_root + "livraison-kiala.php"
                            );
                        } else {
                            window.location.reload();
                        }
                    } else {
                        document.getElementById("AuthTopErr").style.display =
                            "block";

                        $(".loader").css("display", "none");
                    }
                },
            });
        } else if (tabError[0][1] && tabError[0][1] == "mailLostTop") {
            var mail = document.getElementById(tabError[0][1]).value;

            $.ajax({
                url: path_relative_root + "ajax_email_exist.php",
                type: "get",
                data: {mail: mail},
                success: function(res) {
                    if (res == 1) {
                        $.ajax({
                            url:
                                path_relative_root +
                                "ajax_send_new_password_link.php",
                            type: "get",
                            data: {mail: mail},
                            succes: function() {
                                show_lightbox(
                                    "popup_confirm_reinitialisation_mdp"
                                );

                                document.getElementById(
                                    "lostpwdTopErr"
                                ).style.display =
                                    "none";
                                document.getElementById(
                                    "lostpwdTopSuccesTitle"
                                ).style.visibility =
                                    "hidden";

                                hideLoader();
                            },
                        });
                    } else {
                        document.getElementById("lostpwdTopErr").style.display =
                            "block";

                        hideLoader();
                    }
                },
            });
        } else if (tabError[0][1] && tabError[0][1] == "clientMailAuth") {
            $.ajax({
                url: path_relative_root + "ajax_login_allowed.php",
                type: "get",
                data: {
                    mail: document.getElementById(tabError[0][1]).value,
                    mdp: document.getElementById(tabError[1][1]).value,
                    is_top: false,
                },
                success: function(res) {
                    hideLoader();

                    if (res == 1) {
                        $("#auth").submit();

                        return true;
                    } else {
                        document.getElementById("AuthErrErr").style.display =
                            "block";

                        return false;
                    }
                },
            });
        } else if (tabError[0][1] && tabError[0][1] == "mailLost") {
            var mail = document.getElementById(tabError[0][1]).value;

            $.ajax({
                url: path_relative_root + "ajax_email_exist.php",
                type: "get",
                data: {mail: mail},
                success: function(res) {
                    if (res == 1) {
                        $.ajax({
                            url:
                                path_relative_root +
                                "ajax_send_new_password_link.php",
                            type: "get",
                            data: {mail: mail},
                            success: function(res) {
                                show_lightbox(
                                    "popup_confirm_reinitialisation_mdp"
                                );

                                document.getElementById(
                                    "lostpwdErr"
                                ).style.display =
                                    "none";
                                document.getElementById(
                                    "lostpwdSuccesTitle"
                                ).style.visibility =
                                    "hidden";

                                hideLoader();

                                return false;
                            },
                        });
                    } else {
                        document.getElementById("lostpwdErr").style.display =
                            "block";

                        hideLoader();

                        return false;
                    }
                },
            });
        }
    } else {
        $(".loader").css("display", "none");
    }
}

// Ensures this.value is non-empty
// Binds to HTMLInputElement
// Returns true if value is non-empy, false otherwise
function nonEmpty() {
    return !!this.value.trim();
}

// Adds "display" rule to bond element's style with a value of "block"
function show() {
    this.style.display = "block";
}

// Adds "display" rule to bond element's style with a value of "none"
function hide() {
    this.style.display = "none";
}

// Adds leading zeroes to a given value
function zeroPad(nbr, base) {
    var zpad;

    base = parseInt(base) > 1 ? base : 10;
    zpad = nbr < base ? "0" : "";

    return zpad + nbr.toString(base).toUpperCase();
}

// Alters item quantity in cart
function changeQte(type) {

    var types = [ 'plus', 'less' ],
        item, loader, qtty, ajax_url;

    if (types.includes(type)) {

        item = this.getValue('item_id', parseFloat);
        loader = document.getElementById('qte_loader_' + item);
        qtty = this.getValue('selected_qte', parseFloat);

        this.qtty_select_wrap.style.display = 'none';
        loader.style.display = 'initial';

        switch (type) {
            case 'plus':
                qtty += 1;
                break;
            case 'less':
                qtty -= 1;
                break;
        }

        $.ajax({
            url : path_relative_root + create_link("order_basket"),
            type : 'post',
            data : {
                action : 'del',
                panierId : item,
                cad  : 'non',
                pointcad : 0,
                qteProd : qtty,
                change_qte : true
            },
            cache : false,
            success : function (res) {

                try {
                    res = JSON.parse(res);
                } catch (e) {
                    console.error('JSON ERROR: could not parse data');
                    return false;
                }

                res = $('<div />').html(res.datas);

                document.getElementById('main_cart_wrapper').innerHTML = $('#main_cart_wrapper', res).html(); 
                if (wShop.$refs.wCouponForm) {
                    wShop.$refs.wCouponForm.reloadBloc+=1;
                }

                //update top cart
                var response = ajax_file("ajax_show_basket.php");
                document.getElementById('cart_top').innerHTML = response;

                /* Initialiser le swiper cart_slider_wrapper sur le panier */
                initialise_tunnelSwiper();

                /* Initialiser le swiper productVisualMulti sur le panier */
                initialise_productSwiper();

                /* Activer le bloc wishlist sur le panier */
                window.setTimeout(function() {
                    if($(".cart_main_table.wishlist").length) {
                        var wishlist_title = $('body.step_1 #main_cart_wrapper .cart_main_title.wishlist');
                        var wishlist_bloc = $('body.step_1 #main_cart_wrapper .cart_main_table.wishlist');

                        $(wishlist_title).click(function(){
                            $(this).toggleClass('active');
                            $(wishlist_bloc).toggleClass('active');
                        })
                    }

                     /*Déplacer le prix produit dans la wishlist auprès de titre*/
                    var product_mod = $('body.cart .cart_main_table.wishlist .product_mod .cart_product_title');
                    var wrapper_cart_product_price = $('body.cart .cart_main_table.wishlist .wrapper_cart_product_price');
                    $(wrapper_cart_product_price).appendTo(product_mod);
                }, 100);  
    
                /* Activer le cart_slider_wrapper sur le panier */    
                var cart_slider_wrapper_title = $('body.step_1 #cart_slider_wrapper .full_w_sldr_title');
                var cart_slider_wrapper_bloc = $('body.step_1 #main_cart_wrapper #cart_slider');

                $(cart_slider_wrapper_title).click(function(){
                    $(this).toggleClass('active');
                    $(cart_slider_wrapper_bloc).toggleClass('active');
                });
            },
            complete : function() {
                getWishlistProducts();
            }
        });
    }
}

/**
 * Reload product from ref
 */
function loadProduct(new_id, type, page, itm_rr_id, color_select = '') {
    var form = this;
    var product_id = (this.getValue('produit_id')) ? this.getValue('produit_id') : this.dataset.prodid ;
    var old_id = (product_id.length > 0) ? product_id : this.dataset.prodid;
    var col_id = (this.getValue('idCouleur')) ? this.getValue('idCouleur') : document.getElementById('idCouleur_'+ old_id + itm_rr_id).value;
    var sze_id = (this.getValue('idTaille')) ? this.getValue('idTaille') :  document.getElementById('idTaille_'+ old_id + itm_rr_id).value;
    var act = this.getValue('act');
    var qte_itm = 1;
    if(this.elements.namedItem('qteProd')  ) {
        var qte_itm = this.elements.namedItem('qteProd').value;
    }

    var is_model = this.getValue('hdn_is_model');
    var is_wishlist = $('#wishlist').length > 0;
    var is_achat_express = $('#is_achat_express_v2').val() == 1;

    var cpt_prod = itm_rr_id !== '' ? itm_rr_id.replace('_', '') : '';

    var data = {
        produit_id : old_id,
        produit_principal : old_id,
        couleurProd : col_id,
        tailleProd : sze_id,
        act : act,
        qteProd : qte_itm,
        hdn_product_id : old_id,
        hdn_is_model : is_model,
        produit_id_new : new_id,
        type : type,
        type_page : (page !== undefined && page !== '' ) ? page : 'product',
        is_achat_express : is_wishlist ? 1 : 0,
        is_achat_express_v2 : is_achat_express && !is_wishlist ? 1 : 0,
        cpt_prod : cpt_prod-1
    };

    if (is_wishlist) {
        data.wishlist = true;
    }

    $.ajax({
        type : 'post',
        url : path_relative_root + create_link("ajax_get_product_regroup_ref"),
        data : data,
        success : function (res) {

            try {
                res = $.parseJSON(res);
            } catch (e) {
                console.error('parseJSON');
                return;
            }

            if (res.success == 'ok') {

                var datas = res.datas;
                if (data.type_page == 'product' || data.type_page == 'assoc' ) {

                    var $html_main = $(stripCombo(datas.html_main));

                    var main_wrapper = $('.product_main_wrapper', $html_main);

                    var product_associations_bloc = $('#product_associations_bloc', $html_main);
                    var product_cms_bloc = $('#product_cms_bloc', $html_main);
                    var product_netreviews_bloc = $('#product_netreviews_bloc', $html_main);

                    var zoom_wrapper = $('.zoom_main_wrapper', $html_main);
                    var feats_wrapper = $('.product_feats_wrapper', $html_main);

                    if (data.is_achat_express_v2 || is_achat_express) {
                        // Photo et information
                        var itm_id = old_id + (itm_rr_id !== '' ? itm_rr_id.replace('_','-') : '');
                        $('#itm-' + itm_id).replaceWith($html_main);

                        var new_itm_id = new_id + (itm_rr_id !== '' ? itm_rr_id.replace('_','-') : '');
                        var margin_right = $('#itm-' + new_itm_id ).next().css('margin-right');

                        if ($(".homepage").length>0 || $(".satellite_store_details").length>0) {
                            save_description = $('#itm-' + new_itm_id + ' .wrapper_description').html();
                            $('#itm-' + new_itm_id + ' .wrapper_description').html(save_description);
                            save_item_title = $('#itm-' + new_itm_id + ' .item_title').html();
                            $('#itm-' + new_itm_id + ' .item_title').html('<span>'+save_item_title+'</span>')
                            $('#itm-' + new_itm_id + ' .item_price').insertBefore('#itm-' + new_itm_id + '.wrap_rolloverproduit');
                            if($(".satellite_store_details").length>0) {
                                $('#itm-' + new_itm_id ).addClass('swiper-slide');
                                $('#itm-' + new_itm_id ).css('margin-right',margin_right);
                            }
                        } else if ($(".product_page").length>0 || $(".rayon_lookbook").length>0) {
                            if ($(".product_page").length>0) {
                                $('#itm-' + new_itm_id + ' .imgWrapper').prepend($('#itm-' + new_itm_id + ' .item_price'));
                                $('#itm-' + new_itm_id ).addClass('assoc'); 
                                $('#itm-' + new_itm_id ).css('margin-right',margin_right);
                            } else if($(".rayon_lookbook").length>0) {
                                $('#itm-' + new_itm_id + ' .imgWrapper').addClass('imgWrapperinlook');
                            }
                        }
                        $('input.color_' + new_id + itm_rr_id).prop('checked', false);
                        $('input#color_' + new_id + '_' + data.couleurProd + itm_rr_id).prop('checked', true);
                        
                        if ($('#' + data.couleurProd).val() == '' ||$('#' + data.couleurProd).val() !== color_select) {
                            $('#' + data.couleurProd).val(color_select);
                        }
                    } else if (is_wishlist) {
                        // Photo et information
                        $('.product_main_wrapper', '#lightbox_achat_express').html(main_wrapper.html());
                        
                        // Description compo, livraison, guide tailles
                        $('.product_feats_wrapper', '#lightbox_achat_express').html(feats_wrapper.html());
                    } else {
                        var color = $("#ligne_couleur").html();

                        // Update HTML Photo et information
                        $('.product_main_wrapper', '#site_global_wrap').html(main_wrapper.html());

                        // Update HTML Associated products
                        $('#product_associations_bloc', '#site_global_wrap').html(product_associations_bloc.html());
                        
                        // Update HTML CMS module
                        $('#product_cms_bloc', '#site_global_wrap').html(product_cms_bloc.html());

                        // Update HTML Netreview bloc
                        $('#product_netreviews_bloc', '#site_global_wrap').html(product_netreviews_bloc.html());

                        // Update HTML Zoombox
                        $('.zoom_main_wrapper', '#site_global_wrap').html(zoom_wrapper.html());
                        
                        // Update HTML Description compo, livraison, guide tailles
                        $('.product_feats_wrapper', '#site_global_wrap').html(feats_wrapper.html());

                        // Update HTML Switch color
                        $("#ligne_couleur").html(color);
                        $('input.color').prop('checked', false);
                        $('input#color_' + data.couleurProd).prop('checked', true);

                        // Init associated product
                        if ($(".product_associations_wrapper").length > 0) {
                            var triggerAimerez = $("#show_aimerez");
                            var triggerLook = $("#show_look");
                            var gondoleAimerez = $("#gondole_aimerez");
                            var gondoleLook = $("#gondole_look");

                            triggerAimerez.on("click touch", function () {
                                triggerLook.removeClass("active");
                                triggerAimerez.toggleClass("active");
                                gondoleAimerez.toggleClass("active");
                                gondoleLook.removeClass("active");
                            });

                            triggerLook.on("click touch", function () {
                                triggerAimerez.removeClass("active");
                                triggerLook.toggleClass("active");
                                gondoleAimerez.removeClass("active");
                                gondoleLook.toggleClass("active");
                            });
                        }

                        // On scroll en haut de la page dans le cas d'un regroupement
                        $('html, body').animate({
                            scrollTop: 0
                        }, 500);

                        /* Ajout overlay scrollbar sur le product_feats_wrapper FP */
                        var fp_product_feats_wrapper = $('body.product_page .product_feats_wrapper .thumbcnt .thumbslide');
                        fp_product_feats_wrapper.overlayScrollbars({});

                        window.setTimeout(function() {
                            if($("#jqzoom .swiper-slide").length > 0) {
                                var main_productVisu = new Swiper('#jqzoom', {
                                    slidesPerView: 1,
                                    navigation: {
                                        nextEl: '.swiper-button-next',
                                        prevEl: '.swiper-button-prev',
                                    },
                                    pagination: {
                                        el: ".swiper-pagination",
                                        type: "progressbar",
                                    },
                                    preloadImages: false,
                                    lazy: isLazyLoad,
                                    loadPrevNext: true,
                                    loadOnTransitionStart: true,
                                    loop: true,
                                });
                            }
                        }, 1000);
                    }

                    if ($("#buyNowSwiper").length > 0) {
                        var buyNowSwiper = document.getElementById("buyNowSwiper");

                        if (buyNowSwiper.getElementsByClassName("swiper-slide").length) {

                            buyNowSwiper = new Swiper("#buyNowSwiper", {
                                sliderPerView: 1,
                                nextButton: '.swiper-button-next',
                                prevButton: '.swiper-button-prev',
                            });
                        }
                    }

                    initFPCharacts();
                } else if (type_page == 'look') {
                    var $html_main = $(stripCombo(datas.html_main));

                    var color = $("#ligne_couleur").html();

                    var main_wrapper = $('.product_main_wrapper', $html_main);

                    var feats_wrapper = $('.product_feats_wrapper', $html_main);

                    // Photo et information
                    $('.product_main_wrapper', '#lightbox_achat_express').html(main_wrapper.html());
                    // Description compo, livraison, guide tailles
                    $('.product_feats_wrapper', '#lightbox_achat_express').html(feats_wrapper.html());


                    $('#ref_look_' + produit_principal).html($('#ref_look_' + produit_principal, $html_main).html());
                    $('#look_add_lightbox_' + produit_principal).html($('#look_add_lightbox_' + produit_principal, $html_main).html());

                    calculTotal('slide_look_detail');
                    $('#itm_thumb_list').empty();
                    var thumbnails = loadPrevisu();
                    parseThumbList(thumbnails);

                    var $id_current = id_prod.substring(id_prod.lastIndexOf("_") + 1);
                    var i = array_look.indexOf(parseInt($id_current));

                    if (i != -1) {

                        array_look.splice(i, 1);
                    }

                    // Une fois les informations chargés on vas mettre à jour le select des quantités avec le nouveau produit choisie
                    showQteListeGamme('liste_qte_' + datas.product.id,  datas.product.id, path_relative_root);

                }

                json_colisages = res.stock;
                // On definie les tailles qui ont encore du stock
                if (!data.is_achat_express_v2) {
                    setAvailableSizes.call($('[name="itm_color"]:checked', $(form)));
                }

                if (wShop.$refs.wAlertestockForm) {
                    wShop.$refs.wAlertestockForm.reloadBloc += 1;
                }

                let sizes_tab = json_colisages[$('#prod_info_' + datas.product.id + ' input[name="itm_color"]:checked').val()];
                let has_stock = false;
                for (size_id in sizes_tab) {
                    if (sizes_tab.hasOwnProperty(size_id)) {
                        if (sizes_tab[size_id]) {
                            has_stock = true;
                            $("#size_"+size_id).removeClass('disabled');
                        } else {
                            $("#size_"+size_id).addClass('disabled');
                        }
                    }
                }

                if (has_stock) {
                    $("#bloc_add_basket").show();
                    $("#bloc_add_alert").hide();
                } else {
                    $("#bloc_add_basket").hide();
                    $("#bloc_add_alert").show();
                }

                //Simulates hover to load images
                $("#search_page #scroll_items .imgWrapper img").mouseover();
                $("#search_page #scroll_items .imgWrapper .w-slider-slide").mouseover();
                $("#search_page #scroll_items .imgWrapper .swiper-slide").mouseover();

                //Simulates scroll (2px) to load images
                setTimeout(function() {
                    window.scrollTo(0, $(window).scrollTop()+3);
                }, 100);

                if ($('.rayon_lookbook').length) {
                    var moveTo = $('#itm-' + new_itm_id + ' .bloc_sous_img .wrap_rolloverproduit');

                    //Moving achat express
                    $('#itm-' + new_itm_id + ' .imgWrapper  .wrap_rolloverproduit form').prependTo(moveTo);

                    //Adding active class to active color
                    $('#itm-' + new_itm_id + ' .imgWrapper  .wrap_rolloverproduit form .prodColor[type=radio]:checked').parent().addClass('active');
                }

            } else {
                $('#btn_error').fadeIn();
            }
        },
        complete : function () {

            var heads      = $("[data-tab-head]");
            var containers = $("[data-tab-container]");

            if (window.YT_Player !== undefined) {

                window.YT_Player.destroy();

                window.YT_Player = undefined;
                window.YT_vid_id = undefined;
            }

            var activateTab = function(event) {
                event.preventDefault();

                var element        = $(this);
                var tab_number     = element.attr("data-tab");
                var head_container = element.parent();
                var container_name, container, scrollpane;

                head_container.addClass("current").siblings().removeClass("current");
                container_name = head_container.parent().attr("data-tab-head");
                container      = containers.filter("[data-tab-container='" + container_name + "']");
                scrollpane     = container.find("[data-tab]").hide().filter("[data-tab='" + tab_number + "']").show().find('.scroll-pane');

                if (scrollpane.length > 0) {
                    scrollpane.jScrollPane();
                }
            };

            setTimeout(function () {

                if ($("#visual_scroller").length > 0) {

                    var productThumbs = new Swiper('#visual_scroller', {
                        slidesPerView: 'auto',
                        spaceBetween: 14,
                        direction: 'vertical',
                        freeMode: true,
                        watchSlidesVisibility: true,
                        watchSlidesProgress: true,
                        breakpoints: {
                            1300: {
                                direction: 'horizontal',
                                slidesPerView: 4,
                                spaceBetween: 8,
                            },
                        },
                    });
                }

                if ($("#visual_scroller .swiper-slide").length < 4) {
                    $('.more_vis').addClass('few_pics');
                }
                window.setTimeout(function() {
                    if($("#jqzoom .swiper-slide").length > 0) {
                        var main_productVisu = new Swiper('#jqzoom', {
                            slidesPerView: 1,
                            centeredSlides: true,
                            navigation: {
                                nextEl: '.swiper-button-next',
                                prevEl: '.swiper-button-prev',
                            },
                            pagination: {
                                el: ".swiper-pagination",
                                type: "progressbar",
                            },
                            preloadImages: false,
                            lazy: isLazyLoad,
                            loadPrevNext: true,
                            loadOnTransitionStart: true,
                            loop: true,
                        });
                    }
                }, 1000);

                if (typeof(new_itm_id)!='undefined') {
                    var productSwiper = new Swiper('#itm-' + new_itm_id +  '.productSwiper', {
                        slidesPerView: 1,
                        observer: true,
                        observeParents: true,
                        navigation: {
                            nextEl: '.rollover_rayonsNext',
                            prevEl: '.rollover_rayonsPrev',
                        },
                        preloadImages: false,
                        lazy: isLazyLoad,
                        loadPrevNext: true,
                        loadOnTransitionStart: true
                    });
                }                

                /* Fin reinitialisation sliders visus */

                var more_vis = $('.more_vis');
                var grabber;

                more_vis.each(function () {

                    var jqzoom = $(this).next('#jqzoom');

                    if (jqzoom.length > 0) {

                        $(this).height(jqzoom.height());

                        if (document.getElementById('visual_scroller')) {

                            setTimeout(function () {

                                grabber = new Grabber(document.getElementById('visual_scroller'), true, false);
                            }, 250);
                        }
                    }

                    var zoom_box_wrapper = $(this).next('#zoom_box_wrapper');

                    if (zoom_box_wrapper.length > 0) {

                        $(this).height(zoom_box_wrapper.height());

                        if (document.getElementById('thumbs_scroller')) {

                            setTimeout(function () {

                                grabber = new Grabber(document.getElementById('thumbs_scroller'), true, false);
                            }, 250);
                        }
                    }

                });

                // Resetting alert stock field
                $('.bloc_add_alert_form').hide();

                var productImgsWrapper = $(".productImgsWrapper");
                var productDescrWrapper = $(".productDescrWrapper");

                $(document).on("scroll", function () {

                    var scrollTop = $(document).scrollTop();
                    var newTop = (scrollTop - (productImgsWrapper.innerHeight() - productDescrWrapper.innerHeight()));

                    if(scrollTop >= productImgsWrapper.innerHeight() - productDescrWrapper.innerHeight()) {

                        productDescrWrapper.css("top", "-" + newTop + "px");
                    } else {

                        productDescrWrapper.css("top", "auto");
                    }
                });

                $(window).on('resize', function () {

                    more_vis.each(function () {

                        var jqzoom = $(this).next('#jqzoom');

                        if (jqzoom.length > 0) {

                            $(this).height(jqzoom.height());
                        }

                        var jqzoom = $(this).next('#zoom_box_wrapper');

                        if (jqzoom.length > 0) {

                            $(this).height(zoom_box_wrapper.height());
                        }
                        
                    });
                });

                setTimeout(function () {
                    checkSubmitState();
                    eventButton();
                }, 300);
            }, 250);

            heads.each(function(){
                var head = $(this);
                var children = head.children();
                children.removeClass("current").filter(":first").addClass("current");
                children.find('[data-tab]').click(activateTab);
            });

            $("[data-tab-container]").children('div').eq(Math.abs(1 - $('.current', "[data-tab-head]").index())).css('display', 'none');

            var assocs_elm = $('#assocs_slider', '#site_global_wrap').get(0);
            var ranges_elm = $('#ranges_slider', '#site_global_wrap').get(0);

            getAvailableQuantity.call($('form.product_info', '#site_global_wrap').get(0), data.produit_id_new, data.qteProd,'', data.couleurProd, itm_rr_id, is_wishlist, false);

            $('#addToWishlistButton', '#site_global_wrap').on('click', addToWishlist);

            if (!data.is_achat_express_v2) {
                checkProductInWishlist(data.produit_id_new);
            }

            if (is_wishlist) {
                // event sur le bouton d'ajout à la wishlist
                $("#addToWishlistButton").on("click", addToWishlist);
            }

            if ($(".productSwiper").length) {

                $('body').on('mouseenter touchstart','.productSwiper',function(){

                    if($(this).find('.swiper-slide-on').hasClass('cache') && !$(this).find('.swiper-slide').hasClass('video')) {

                        $(this).find('.swiper-wrapper').html($(this).find('.swiper-slide-on').html());
                        $(this).find('.swiper-slide-off').remove();
                        $(this).find('.swiper-slide-on').remove();

                        var productSwiper = new Swiper($(this), {
                            slidesPerView: 1,
                            observer: true,
                            observeParents: true,
                            navigation: {
                                nextEl: '.rollover_rayonsNext',
                                prevEl: '.rollover_rayonsPrev',
                            },
                            preloadImages: true,
                            lazy: false,
                            loadPrevNext: true,
                            loadOnTransitionStart: true
                        });
                    }
                });
            };

            //Simulates hover to load images
            $("#search_page #scroll_items .imgWrapper img").mouseover();
            $("#search_page #scroll_items .imgWrapper .w-slider-slide").mouseover();
            $("#search_page #scroll_items .imgWrapper .swiper-slide").mouseover();

            //Simulates scroll (2px) to load images
            setTimeout(function() {
                window.scrollTo(0, $(window).scrollTop()+3);
            }, 100);

            // Swiper
            setTimeout(function() {
                if($("#productAssociationSwiperAimerez .swiper-slide.assoc").length > 2) {    
                    var productAssociationSwiperAimerez = new Swiper('#productAssociationSwiperAimerez', {
                        slidesPerView: 3,
                        spaceBetween: 25,
                        loop: false,
                        observer: true,
                        observeParents: true,
                        preloadImages: false,
                        lazy: isLazyLoad,
                        loadPrevNext: true,
                        loadOnTransitionStart: true,
                        navigation: {
                            nextEl: '.swiper-button-next.aimerez_btn',
                            prevEl: '.swiper-button-prev.aimerez_btn',
                        },
                        pagination: {
                            el: ".swiper-pagination.aimerez.aimerez_btn",
                            type: "progressbar", 
                        },
                    });
                }

                // Swiper
                if ($("#productAssociationSwiperLook .swiper-slide.assoc").length > 2) {    
                    var productAssociationSwiperLook = new Swiper('#productAssociationSwiperLook', {
                        slidesPerView: 3,
                        spaceBetween: 25,
                        loop: false,
                        observer: true,
                        observeParents: true,
                        preloadImages: false,
                        lazy: isLazyLoad,
                        loadPrevNext: true,
                        loadOnTransitionStart: true,
                        navigation: {
                            nextEl: '.swiper-button-next.look_btn',
                            prevEl: '.swiper-button-prev.look_btn',
                        },
                        pagination: {
                            el: ".swiper-pagination.look",
                            type: "progressbar",
                        },
                    });
                }
            }, 1000);

            if (window.lazyload !== undefined) {
                lazyload.init();
            }
        }        
    });  
}

// Called upon color change in product page
function onItemColorChange(form, is_regroup, itm_rr_id) {

    var control, prod_id;
    itm_rr_id = itm_rr_id !== undefined ? itm_rr_id : '';
    var is_wishlist = $('#wishlist').length > 0;
    var is_achat_express_v2 = ($('#is_achat_express_v2').val() == 1 && !is_wishlist);

    if (this.checked) {
        var control_id = form.elements.namedItem('produit_id');
        control_id = (control_id.value)? control_id.value : form.elements.namedItem('produit_id')[0].value;
        control = form.elements.namedItem('idCouleur');
        control.value = this.value.trim();
        var input_value = (control.value !== 'undefined' && control.value !== '') ? control.value : this.value.trim();
        prod_id = form.getValue('itm_id_' + input_value + itm_rr_id).trim();
        var type_page = form.querySelector('[name="type_page"]');
        var is_assoc = (type_page && type_page.value == "assoc" && $('.wrapper_panier').length == 1) ? 'assoc' : '';
        var color_select = this.value;

        if (prod_id === "") {
            prod_id = control_id;
        }

        if (is_regroup) {
            loadProduct.call(form, prod_id, 'couleur' , is_assoc, itm_rr_id, color_select);

        } else {
            // On regarde si le produit est dejà dans la wishlist
            if (!is_achat_express_v2) {
                var in_wishlist = checkProductInWishlist();
                changeBtnAddWishlist(in_wishlist, prod_id);
            }

            var img = $(this).data('pic');
            changeVisu('img_large', img);

            // On definie les tailles qui ont encore du stock
            setAvailableSizes.call(this);
        }       
    }    
}

// Called upon size change in product page
function onItemSizeChange(form, event, itm_rr_id) {
    var control;
    var is_wishlist = $('#wishlist').length > 0;
    var is_achat_express_v2 = ($('#is_achat_express_v2').val() == 1 && !is_wishlist);
    var id = (form.getAttribute("data-prodid")) ? form.getAttribute("data-prodid") : form.elements.produit_id.value;
    var elem = is_achat_express_v2 ? '_' + id + (itm_rr_id || '') : '';
    control = form.elements.namedItem('tailleProd');
    control.value = this.value.trim();

    itm_rr_id = itm_rr_id !== undefined ? itm_rr_id : '';
    
    if (this.checked) {
        if (window.dataLayer !== undefined) {
            window.dataLayer.push({
                'event': 'changeSize',
                'produit': {
                    'size_value': ($(this).attr('data-nom')),
                }
            });
            if (window.dataLayer[0].product) {
                window.dataLayer[0].product.size = ($(this).attr('data-nom'));
            }
        }

        if ($('#sizeerror').is(':visible')) {

            $('#sizeerror').slideUp();
        }

        // En fonction de la couleur on regarde si le produit est disponible
        getAvailableQuantity(id, $('#qteProd_' + id + itm_rr_id).val(), 'product', event, itm_rr_id, is_wishlist, true);

        // On regarde si le produti est dejà dans la wishlist
        if (!is_achat_express_v2) {
            var in_wishlist = checkProductInWishlist(id);
            changeBtnAddWishlist(in_wishlist);
        }
    }
}

// Refreshes available quantity
function getAvailableQuantity(id, qty, from, event, itm_rr_id, is_wishlist, add_product) {
    itm_rr_id = (itm_rr_id !== undefined) ? itm_rr_id : '';
    var is_achat_express = $('#is_achat_express_v2').val() == 1;
    var elem = is_achat_express ? '_' + id + itm_rr_id : '';
    var form = (this instanceof HTMLFormElement) ? this : document.getElementById('prod_info_' + id + itm_rr_id);
    var color_id = (elem == "") ? $('input[name="itm_color"]:checked').val() : ($('#couleurProd' + elem).val() !== undefined) ? $('#couleurProd' + elem).val() : $('input.color' + elem + ':checked').val();
    var size_id = $('#tailleProd' + elem).val();
    var inputElem = document.getElementById('produit_lot_nb' + elem);
    var plnb = (inputElem !== null) ? inputElem.value : $('#produit_lot_nb' + elem).val();
    var inputProduct = document.getElementById('produit_id' + elem);
    var produit_id = (inputProduct !== null) ? inputProduct.value : $('#produit_id' + elem).val();

    // Si on a pas de taille ni de couleur déjà selectionné alors on ne fait pas de vérification
    if (color_id == '' || size_id == '') {
        return;
    }

    // Prepare ajax datas
    var data = {
        idprod: produit_id,
        idcouleur: color_id,
        idtaille: size_id,
        json: '1'
    };

    if (from == 'basket') {
        data.suff = produit_id;
    }

    if (plnb) {
        data.produit_lot_nb = plnb;
    }

    if (add_product) {
        $.ajax({
            url: path_relative_root + 'ajax_liste_qte.php',
            type: 'get',
            async: false, // Should be async but would then need a loader so...
            dataType: 'json',
            data: data,
            success: function (res) {
                if (res.stock_tampon == 0) {
                    $(".eclat_product_nouveaute_fiche_produit").show();
                    $("#prod_info" + elem + " .rollover_left").hide();
                    $("#qteProd").val(0);

                    if (!is_achat_express) {
                        $("#bloc_add_basket").hide(); // add basket
                    }

                    //Alert Stock by product
                    if (res.enableAlertStock) {
                        $(".bloc_add_alert" + elem).show();

                        if (is_achat_express) {
                            $(".bloc_add_alert" + elem + " .bloc_add_alert_form").show();
                        }
                    } else {
                        if (is_achat_express) {
                            $("#bloc_size_not_available" + elem).show();
                        } else {
                            $(".product_main_wrapper #size_is_not_available").show();
                        }
                    }

                    $('#ligne_qte').append($('.restock_date'));
                    $('.taunt_text').css('display', 'none');
                } else {
                    if (res.stock_tampon > 0 && res.stock_tampon <= 30) {
                        if (res.stock_tampon == 1) {
                            $('.taunt_text').html(Translator.translate('last_taunt', res.stock_tampon));
                        } else {
                            $('.taunt_text').html(Translator.translate('last_taunts', res.stock_tampon));
                        }
                        $('.taunt_text').css('display', 'block');
                    } else {
                        $('.taunt_text').css('display', 'none');
                    }

                    if (is_achat_express) {
                        waitingAddToBasket(event, form, '', itm_rr_id);
                        $("#bloc_size_not_available" + elem).hide();
                        $(".bloc_add_alert" + elem).hide();
                    } else {
                        $(".eclat_product_nouveaute_fiche_produit").hide();
                        $(".bloc_add_alert").hide(); // newsletter alert stock
                        $(".product_main_wrapper #size_is_not_available").hide(); //no stock
                        $("#bloc_add_basket").show(); // add basket
                        $("#qteProd").val(1);
                    }
                    pushIntoDatalayer(res.dataLayer_object);
                }
            }
        });
    }
}

function closeSizeNotAvailable(elem) {

    // Hide bloc bloc_size_not_available_
    $("#bloc_size_not_available_" + elem).hide();

    // Show bloc colors and size
    $("#prod_info_" + elem + " .rollover_left").show();
    $("#ligne_pointure_" + elem).show();
    $("#ligne_couleur_" + elem).show();
}

function waitingAddToBasket_legacy(
    produit_id,
    suff,
    panier_id,
    titreObjet,
    idTaille,
    lastTaille,
    idCouleur,
    lastCouleur,
    toEval,
    page_name,
    path_web,
    enable_omniture,
    is_not_produit,
    is_lightbox_specifique
) {
    var id = "";
    if (is_not_produit == true) id += "_" + produit_id;

    if (is_lightbox_specifique)
        add_produit_specifique(produit_id, idCouleur, idTaille);
    else
        window.setTimeout(
            'addToBasket_legacy("' +
                produit_id +
                '", "' +
                suff +
                '", "' +
                panier_id +
                '", "' +
                titreObjet +
                '", "' +
                idTaille +
                '", "' +
                lastTaille +
                '", "' +
                idCouleur +
                '", "' +
                lastCouleur +
                '","' +
                toEval +
                '", "' +
                page_name +
                '", "' +
                path_web +
                '", ' +
                enable_omniture +
                ", " +
                is_not_produit +
                ");",
            1000
        );

    $("#bloc_btn_active" + id).attr("class", "cache");
    $("#bloc_btn_loader" + id).attr("class", "f_right");
}

function addToBasket_legacy(
    produit_id,
    suff,
    panier_id,
    titreObjet,
    idTaille,
    lastTaille,
    idCouleur,
    lastCouleur,
    toEval,
    page_name,
    path_web,
    enable_omniture,
    is_not_produit
) {
    var id = "";

    if (is_not_produit == true) id += "_" + produit_id;

    prix_id = "prixU";
    if (is_not_produit == true) {
        var code_color = $("#couleurUnique_" + produit_id).text();
        var nom_color = $("#couleurUnique_" + produit_id).text();
        var prix_id = "prixU_" + produit_id;
    }

    var array_panier = newUpdatePanier_legacy(
        produit_id,
        suff,
        panier_id,
        document.getElementById(prix_id).value,
        path_web,
        titreObjet,
        idTaille,
        lastTaille,
        idCouleur,
        lastCouleur
    );

    if (array_panier != false) {
        var type = "";

        if (array_panier[1] == "false" || array_panier.length == 0) {
            type = "err";
        }

        omnitureEvent = "";
        var response = ajax_file(path_web + "ajax_show_basket.php");
        $("#cart_top").html(response);

        if (array_panier[1] === 0) {
            $("#nb_article").html(array_panier[1]);
            omnitureEvent = "";
        } else if (array_panier[1] == 1) {
            $("#nb_article").html(array_panier[1]);
            $("#panier")
                .attr("href", path_web + create_link("order_basket"))
                .attr("style", "cursor:pointer;");

            omnitureEvent = "scOpen,scAdd"; // le premier produit ajout� au panier
        } else {
            $("#nb_article").html(array_panier[1]);
            $("#panier")
                .attr("href", path_web + create_link("order_basket"))
                .attr("style", "cursor:pointer;");

            omnitureEvent = "scAdd"; // il y a d�j� un produit au panier
        }

        newshowmodal(
            array_panier[1],
            type,
            path_web,
            is_not_produit,
            produit_id
        );

        $("#bloc_btn_active" + id).attr("class", "");
        $("#bloc_btn_loader" + id).attr("class", "f_right cache");

        if (array_panier[1] > 0 && enable_omniture == true) {
            void s.t();
            s.pageName = "'" + page_name + "'";
            s.products = ";" + produit_id;
            s.events = omnitureEvent;
            void s.t();
        }
    } else {
        $("#bloc_btn_active" + id).attr("class", "");
        $("#bloc_btn_loader" + id).attr("class", "f_right cache");
    }

    eval(toEval);

    if (array_panier != false) {
        if (
            navigator.userAgent.indexOf("MSIE") != -1 &&
            navigator.userAgent.indexOf("6.") != -1
        ) {
            var svn = document.getElementsByTagName("SELECT");
            for (a = 0; a < svn.length; a++) {
                svn[a].style.visibility = "hidden";
            }
        }
    }

    return false;
}

function newUpdatePanier_legacy(
    idProduit,
    i,
    paniervirtuelId,
    prixU,
    path_web,
    titreObjet,
    idElTaille,
    lastTaille,
    idElCouleur,
    lastCouleur
) {
    if (i != "") {
        i = "_" + i;

        prixU = $("#prixU" + i).val();
    }

    var couleurs = document.getElementById("couleurProd" + i);
    var tailles = document.getElementById("tailleProd" + i);
    var $alertbox = $("#alertbox1");
    var res = "";
    var html_err = "";
    var flagError = false;
    if ($("#lightbox_achat_express").length > 0) {
        from_range = true;
    } else {
        from_range = false;
    }

    html_err = '<div class="txt_alert"></div><div class="rosette"></div>';
    html_err +=
        '<div class="wrapper_btn"><a onclick="closeMultiShad();" href="#" class="btn_alert">OK</a></div>';
    if ($alertbox.length == 0) {
        $alertbox = $('<div id="alertbox1">' + html_err + "</div>");
        $("body").append($alertbox.hide());
    }

    if (tailles && tailles.value == "") {
        res = ajax_file(path_web + "ajax_sprintf.php?arg1=js_error_size");
        flagError = true;
    } else if (couleurs && couleurs.value == "") {
        res = ajax_file(path_web + "ajax_sprintf.php?arg1=js_error_couleur");
        flagError = true;
    } else if (
        document.getElementById("qteProd" + i) &&
        document.getElementById("qteProd" + i).value == ""
    ) {
        res = ajax_file(path_web + "ajax_sprintf.php?arg1=js_error_quantity");
        flagError = true;
    }

    if (flagError) {
        $alertbox.find(".txt_alert").html(res);
        openMultiShad("alertbox1");
        return false;
    } else {
        var qte = 0;

        if ($("#qteProd" + i)) {
            qte = $("#qteProd" + i).val();
        }

        var idCouleur = 0;
        var idTaille = 0;

        if (couleurs && couleurs.value != "none") idCouleur = couleurs.value;
        if (tailles && tailles.value != "none") idTaille = tailles.value;

        var array_response = new Array();
        var response = ajax_file(
            path_web +
                "ajax_upd_panier.php?prixu=" +
                prixU +
                "&idprod=" +
                idProduit +
                "&idcouleur=" +
                idCouleur +
                "&idtaille=" +
                idTaille +
                "&qte=" +
                qte +
                "&panierId=" +
                paniervirtuelId
        );

        if (response) {
            array_response = response.split("//");

            var designation = array_response[0];

            if (titreObjet.match("^[0-9]")) {
                if (designation == 1) {
                    designation += " lot de";
                } else {
                    designation += " lots de";
                }
            }

            designation += " " + titreObjet;

            if (document.getElementById(idElTaille)) {
                if (document.getElementById(idElTaille).selectedIndex) {
                    designation +=
                        " - Taille " +
                        document.getElementById(idElTaille).options[
                            document.getElementById(idElTaille).selectedIndex
                        ].text;
                } else if (lastTaille != "") {
                    designation += " - Taille " + lastTaille;
                }
            }

            if (document.getElementById(idElCouleur)) {
                if (document.getElementById(idElCouleur).selectedIndex) {
                    designation +=
                        " - " +
                        document.getElementById(idElCouleur).options[
                            document.getElementById(idElCouleur).selectedIndex
                        ].text;
                } else if (lastCouleur != "") {
                    designation += " - " + lastCouleur;
                }
            }

            array_response[0] = designation;
        }

        return array_response;
    }
}

// Pretty straightforward: this formats a price
var formatPrice = (function() {
    var THREEDIGITS = new RegExp("\\d{3}", "g");
    var FORMAT = window.price_format_plain;

    // Splits a number into chunks of 3 digits
    function splitThousands(nmbr) {
        var f_chunk = "",
            chunk_length;

        nmbr = String(nmbr);

        chunk_length = nmbr.length % 3;

        if (chunk_length) {
            f_chunk = nmbr.slice(0, chunk_length);
            nmbr = nmbr.substr(chunk_length);
        }

        return (
            f_chunk +
            nmbr.replace(THREEDIGITS, function(match) {
                return " " + match;
            })
        );
    }

    return function(price) {
        var CURRENCY = window.siteCurrency;

        var output;

        if (price.constructor !== String) {
            price = price.toString(10);
        }

        price = price.replace(".", ",");
        price = price.split(",");

        output = FORMAT.replace("[<unit>]", splitThousands(price[0]));
        output = output.replace("[<symbol_right>]", CURRENCY.symbol_right);
        output = output.replace("[<symbol_left>]", CURRENCY.symbol_left);

        if (price[1] === undefined) {
            price[1] = "00";
        } else if (parseFloat(price[1]) < 10) {
            price[1] = "0" + price[1];
        }

        output = output.replace("[<decimal>]", price[1]);
        output = output.replace("[<code>]", CURRENCY.code);

        return output;
    };
})();

// Limits the length of an input
function limitInputLength(max) {
    this.value = this.value.slice(0, max);

    return this;
}

$(document).on("click", ".thumblnk", function(evt) {
    var id_pannel = this.getAttribute("data-pannel");
    var thumbnav = $(this).closest(".thumbnav");
    var thumbcnt = thumbnav.next(".thumbcnt");

    evt.preventDefault();

    thumbnav
        .find(".thumblnk")
        .not(".thumblnk[data-pannel=" + id_pannel + "]")
        .removeClass("current");
    thumbcnt
        .find(".thumbslide")
        .not(".thumbslide[data-pannel=" + id_pannel + "]")
        .removeClass("current");

    thumbnav
        .find(".thumblnk[data-pannel=" + id_pannel + "]")
        .addClass("current");
    thumbcnt
        .find(".thumbslide[data-pannel=" + id_pannel + "]")
        .addClass("current");
});

/*
 * Page presse.html
 */
function showPresse() {
    var width,
        height,
        zoom = $(this).next(".presse_zoom");

    if (zoom.length > 0) {
        var lineHeight = $(".line").height() * 1.9;
        var lineHeightSwiper = lineHeight - 60;

        $("body").addClass("overflow");

        width = (zoom.width() / zoom.parent().width()) * 100;
        height = zoom.height();

        $(".presse_zoom").removeClass("actif");

        zoom.css({width: 0, height: 0});
        zoom.addClass("actif");
        zoom.animate({width: width + "%", height: lineHeight}, 500, function() {
            $(".satellite_presse .swiper-container").css(
                "height",
                lineHeightSwiper
            );
            $(this).css("height", lineHeight);
        });

        $("#shad")
            .fadeTo("slow", 0.8)
            .on("click", closePresse);
    }
}

function closePresse() {
    $("#shad").off("click");

    var zoom = $(".presse_zoom.actif");
    $("body").removeClass("overflow");
    var width = (zoom.width() / zoom.parent().width()) * 100;
    var height = zoom.height();
    $(".close", zoom).css("display", "none");
    zoom.animate(
        {
            width: 0,
            height: 0,
        },
        500,
        function() {
            $(".presse_zoom").removeClass("actif");
            $(".close", zoom).css("display", "block");
            setTimeout(function() {
                zoom.css({
                    width: width + "%",
                    height: "auto",
                });
            }, 500);
        }
    );

    if ($("#shad").is(":visible")) {
        $("#shad").fadeTo("slow", 0, function() {
            $("#shad").css("display", "none");
        });
    }
}

// INSCRIPTION NEWSLETTER
// La gestion de l'inscription à la newsletter
$("input#newsletter_home").click(function() {
    $.ajax({
        url: path_relative_root + "ajax_newsletter_email.php",
        type: "post",
        data: {
            email: $("#newsletter_email").val(),
            provenance: "footer",
            sous_provenance: $("#newsletter_email").attr("data-lang"),
        },
        cache: false,
        success: function(res) {
            var resultat = JSON.parse(res);
            if (resultat.valid == false) {
                $("#newsletter_email").addClass("inputErr");
            } else {
                $("#error_newsletter").removeClass("error");
                $("#error_newsletter").addClass("valid");
                $("#newsletter_email").removeClass("inputErr");
            }
            $("#error_newsletter").html(translate(resultat.message));
        },
    });
});

function closeAlerteStock(elem) {

    // Hide bloc alert stock
    $('.bloc_add_alert_' + elem).hide();
    $("#prod_info_" + elem + " .rollover_left").show();

    // Show bloc colors and size
    $('#ligne_pointure_' + elem).show();
    $('#ligne_couleur_' + elem).show();

    // Reset mail input
    $('.bloc_add_alert_' + elem + ' .alert_stock').show();
    $('.bloc_add_alert_' + elem + ' .bloc_add_alert_form').show();
    $('.bloc_add_alert_' + elem + ' .bloc_add_alert_confirmation').hide();
}

// YOUTUBE LOGIC
var YT_Player, YT_vid_id;

function onYouTubeIframeAPIReady() {

    YT_Player = new YT.Player('popup_video', {
        videoId : YT_vid_id,
        playerVars : {
            rel : 0
        }
    });

    if ($('#videoBox').is(':visible')) {

        YT_Player.playVideo();
    }
}

function initYTPlayer() {

    var tag = document.createElement('script');

    tag.id = 'YT_player_script';
    tag.src = "https://www.youtube.com/iframe_api";

    document.body.appendChild(tag);
}

/**
 * La video dans la fiche produit (champs produit_video) Charge une video à partir de Youtube
 */
function show_video(vid) {

    var video = $('#videoBox');

    $(window).scrollTop(0);

    if (!YT_Player) {

        YT_vid_id = vid;

        if (!document.getElementById('YT_player_script')) {

            initYTPlayer();
        } else {

            onYouTubeIframeAPIReady();
        }
    }

    $('#shad').off('click', stop_video).after(video);
    $('#shad').stop(true, true).fadeIn('slow', function () {

        video.css({ display : 'block', opacity : 0 }).animate({ opacity : 1 }, function () {
        });

        $('#shad').one('click', stop_video);
    });
}

function stop_video() {

    var video = $('#videoBox');

    if ( video.css('display') != 'none' ) {
        video.css({ display : 'block', opacity : 1 }).animate({ opacity : 0 }, function () {

            $('#shad').stop(true, true).fadeOut('slow', function () {

                if (YT_Player !== undefined) {

                    YT_Player.seekTo(0).stopVideo();
                }

                video.css({ display : 'none' });
            });
        });
    }
}

/***** FUNCTION PUSH INTO DATALAYER  *****/

function pushIntoDatalayer (data) {
    if (typeof(data) == 'object') {
        window.dataLayer.push(data);
    } else if (typeof(data) == 'string') {
        window.dataLayer.push(JSON.parse(data));
    }
}

function createDatalayer(prod_id, url) {
    if (productlist_gondole){
        for ( var i = 0 ; i < productlist_gondole.length; i++) {
            prod = productlist_gondole[i];
            if (prod.id == prod_id){
                window.list = prod['list'];
                delete prod['list'];
                dataLayer.push({
                    'event': 'productClick',
                    'ecommerce': {
                        'click': {
                            'actionField': {'list':list},      // Optional list property.
                            'products': [prod]
                        }
                    },
                    'eventCallback': 'function() { document.location = url}',
                });
            }

        }
    }
}

/***** PROD ASSOCS DATA LAYERS *****/

function dataLayer_associated_product () {

    if (window.$('.otherProduct')) {

        var product_id = this.getAttribute('data-produit-id') ? event.currentTarget.getAttribute('data-produit-id') : '';
        var productObj = null;
        if (product_id !== '') {
            if ( data_layer_products.length > 0 ) {
                data_layer_products.forEach(function(item){
                    if ( item.id == product_id ) {
                        productObj = item;
                    }
                })
            }
            var prod = productObj;
            if (productObj !== null && productObj !== undefined ) {
                dataLayer.push({
                    'event': 'productClick',
                    'ecommerce': {
                        'click': {
                            'actionField': {'list': productObj['category']},      // Optional list property.
                            'products': [prod]
                        }
                    },
                    'eventCallback': "function () {document.location = productObj.url}"
                });
            }
        }
    }
}

/**********Gift Card***********/
function toggleGiftCardBalance() {
    var connectBtn = $(".btn_container");

    if (connectBtn.hasClass("notLogged")) {
        location.href=path_relative_root+create_link('order_identification')+'?from=giftcard';
    } else {
        $(".pageGiftCard__header .headerTitles").toggleClass('is-hidden', ''); 
        $(".pageGiftCard__main").toggleClass('is-hidden', '');
        $(".showAmountBtn").toggleClass('is-hidden', '');
    }
};