$(function() {
    var header = document.getElementById("site_head_wrap");
    var search = document.getElementById("search_home");
    var shade = document.getElementById("shad_menu");

    function onWinScroll() {
        if (!document.body.classList.contains("in_tunnel")) {
            var scrollTop = $(document).scrollTop();

            if (header.classList.contains("sticky")) {
                if (scrollTop === 0) {
                    header.classList.remove("sticky");
                    shade.classList.remove("sticky");
                }
            } else {
                if (scrollTop !== 0) {
                    header.classList.add("sticky");
                    shade.classList.add("sticky");
                }
            }
        }
    }

    function onSearchStateChange(evt) {
        if (evt.type === "focus") {
            search.parentElement.classList.add("searching");
        } else if (search.value.trim() === "") {
            search.parentElement.classList.remove("searching");
        }
    }

    $(window).on("scroll", onWinScroll);
    $(search).on({focus: onSearchStateChange, blur: onSearchStateChange});

    onWinScroll();
});


/*************** HOME MODULES SWIPERS *******************/ 

    /* MODULE 1 – SLIDER IMMERSIF */
    if ($('#super_home_swiper .swiper-slide').length) {
        new Swiper('#super_home_swiper', {
            slidesPerView: 1,
            speed: 1500,
            simulateTouch: true,
            autoplay: {
                delay: 5000,
            },
            pagination: {
              el: ".swiper-pagination",
              type: "progressbar",
            },
        });
    }

    $(document).ready(function(){
        $('#super_home_swiper .swiper-slide .home-module-contents .home-module-subTitle div').parents('.home-module-contents').addClass('indent_cta');
    });

    /* MODULE 2 – TÊTES DE GONDOLES */

    if ($('.tg-module-1 .swiperTg .swiper-slide').length) {
        new_swiperTg = new Swiper('.swiperTg', {
            slidesPerView: 4,
            spaceBetween: 17,
            navigation: {
                nextEl: '.cms_list_1_btn.swiper-button-next',
                prevEl: '.cms_list_1_btn.swiper-button-prev',
            },
            pagination: {
              el: ".swiper-scrollbar",
              type: "progressbar",
            },
            lazy: true,
        });
    }

    /* MODULE 3 – LOOK */

    setTimeout(function(){
        $(".look-1 .look-right-1, .look-1 .look-right-2").wrapAll("<div class='look-right'></div>");
    }, 200);

    /* MODULE 5 – EDITO */
    $(document).ready(function(){
        if ($('.edito_swiper.bloc_a .swiper-slide').length) {
            bloc_b_edito_swiper_a = new Swiper('.edito_swiper.bloc_a', {
                slidesPerView: 1,
                speed: 1000,
                allowTouchMove: false,
                loop: true,
                disableOnInteraction: false,
                navigation: {
                    nextEl: '.arrow_wrapper .right-arrow',
                    prevEl: '.arrow_wrapper .left-arrow',
                },
                autoplay: {
                   delay: 5000,
                },
            });
        }
        setTimeout(function(){
            if ($('.edito_swiper.bloc_b .swiper-slide').length) {
                bloc_b_edito_swiper_b = new Swiper(".edito_swiper.bloc_b", {
                    slidesPerView: 1,
                    speed: 1000,
                    allowTouchMove: false,
                    loop: true,
                    disableOnInteraction: false,
                    navigation: {
                        nextEl: '.arrow_wrapper .right-arrow',
                        prevEl: '.arrow_wrapper .left-arrow',
                    },
                    autoplay: {
                       delay: 5000,
                    },
                });
            }
        }, 750);
    });

    /* MODULE 4 – SLIDER SIMPLE */

    if ($('.slider_simple .swiper-slide').length > 1) {
        new Swiper('.slider_simple', {
            slidesPerView: 1,
            speed: 1500,
            lazy: true,
            simulateTouch: true,
            autoplay: {
                delay: 5000,
            }
        });
    }

    $('.image-2 .module_img_4_slide').each(function(){
        if ($(this).find('.swiper-slide').length > 3) {
            new Swiper(this, {
                slidesPerView: 4,
                spaceBetween: 14,
            });
        } else {
            $(this).addClass('no_swiper');
        }
    });