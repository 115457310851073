// Récupération de la valeur de la constante NO_LAZYLOAD pour appliquer le paramètre aux swiper
var isLazyLoad = is_lazy_load ? false : true;

$(function() {

    if($('#FreeShippingBanner').length) {

        $('#site_head_wrap').css('top', 36);
        $('#site_head_wrap').removeClass('bandeau_on');
        $("body").addClass("bandeauDelivery");
    }

    // Le scroll vers le produit
    if ($("#scroll_items").length > 0) {
        var element_id = document.location.hash.substr(3);

        if (element_id !== "" && typeof element_id !== 'undefined') {
            $("html, body").animate(
                {
                    scrollTop: $("#itm-" + element_id).offset().top - 120, // 120 hauteur du menu en scroll
                },
                500
            );
        }
    }

    // Gestion du shad pour le menu
    $(".my_account.is_logged").on("mouseenter", function() {
        $(this).addClass('hover');
        $("#shad_menu").addClass("actif");
    });

    $(".my_account.is_logged").on("mouseleave", function() {
        $(this).removeClass('hover');
        $("#shad_menu").removeClass("actif");
    });

    $(".my_basket").on("mouseenter", function() {
        topCartAppear();
    });

    $(".my_basket").on("mouseleave", function() {
        topCartDesappear();
    });

    $("#shad_menu").on("click", function() {
        if ($('.my_basket').hasClass("hover")) {
            topCartDesappear();
        }
    });

    var menu_timeout = setTimeout(null);

    $(".main_menu_itm")
        .not(".menu_itm_hover")
        .on({
            mouseenter: function() {
                var mainmenu = $(".main_menu");

                clearTimeout(menu_timeout);

                mainmenu.addClass("actif");
            },
            mouseleave: function() {
                menuItemClose();
            },
        });

    $(".menu_itm_hover").on("mouseenter", function(evt) {
        var mainmenu = $(".main_menu");
        var submenu = $(".submenu_wrapper", this);

        evt.preventDefault();

        clearTimeout(menu_timeout);

        if (!mainmenu.hasClass("actif")) {
            $("#shad_menu").addClass("actif");

            mainmenu.addClass("actif");

            menu_timeout = setTimeout(function() {
                submenu.css("display", "flex").fadeIn(200);
            }, 200);
        } else {
            submenu.css("display", "flex");
        }
    });

    function menuItemClose(submenu) {
        clearTimeout(menu_timeout);

        if (submenu) {
            submenu.stop(true, true).fadeOut(200);
        }

        menu_timeout = setTimeout(function() {
            $("#shad_menu").removeClass("actif");
            $(".main_menu").removeClass("actif");
        });
    }

    $(document).on("scroll", function() {
        menuItemClose($(".submenu_wrapper:visible"));
    });

    $(".menu_itm_hover").on("mouseleave", function() {
        menuItemClose($(".submenu_wrapper", this));
    });

    $('.lang_switch').on({
        mouseenter: function() {
            $('.lang_itm:not(.active)').addClass('show');
        },
        mouseleave: function() {
            $('.lang_itm:not(.active)').removeClass('show');
        },
    });

    $('#trigger_recherche').on("click touch", function() {
        $('.wrap_search_field').fadeIn().css("display", "flex");
        $('#shad_menu').addClass('actif');
    });

    $('.close_search').on("click touch", function() {
        closeRecherche();
    });

    $('.wrap_search_field').on({
        mouseleave: function() {
            closeRecherche();
        },
    });


    $("#search_home").on('input', function() {
        var value = $("#search_home").val();

        if(value.length >= 3){
            $('body').addClass('search_in');
        } else {
            $('body').removeClass('search_in');
        }
    });


    $("#filter_sticky").lionbars();
    var filter = $('#rayon_sticky_block');

    if($(window).scrollTop() >= 437){
        filter.addClass('reposition');
    } else if($(window).scrollTop() <= 436){
        filter.removeClass('reposition');
    }

    $(document).on("scroll", function() {
        if($(window).scrollTop() >= 437){
            filter.addClass('reposition');
        } else if($(window).scrollTop() <= 436){
            filter.removeClass('reposition');
        }
    });
    setTimeout(function(){
        if ($(".promo_swiper .swiper-slide").length > 0) {
            promoSwiper = new Swiper(".promo_swiper", {
                centeredSlides: true,
                loop: true,
                slidesPerView: 'auto',
                navigation: {
                    nextEl: '.navpromo.promoNext',
                    prevEl: '.navpromo.promoPrev',
                },
                speed: 1500,
                autoplay: {
                    delay: 4500,
                    disableOnInteraction: false,
                },
                autoplayDisableOnInteraction: false
            });
        }
    }, 500);

    window.setTimeout(function() {
        if ($('#slideCateg .itm_name.btn_select').length) {
            slideCateg = new Swiper("#slideCateg", {
                slidesPerView: 'auto',
                navigation: {
                    nextEl: '.navCatNext',
                    prevEl: '.navCatPrev',
                }
            });
            slideCateg.slideTo($('.itm_name.btn_select').index(),1000,false);
        }

        $('.bloc_sub_categ .navCatNext').click();
        $('.bloc_sub_categ .navCatPrev').click();
    }, 300);

    window.setTimeout(function() {
        $('#slideCateg').addClass('visible');
    }, 1000);


    setTimeout(function(){
        if($('.push_container .swiper-slide').length){
            push_container  = new Swiper(".push_container.swiper-container", {
                slidesPerView: 4.7,
                spaceBetween: 25,
                slidesOffsetAfter: 30,
                centeredSlides: false,
                loop: true,
                loopedSlides: 50,
                breakpoints: {
                    1360: {
                        slidesPerView: 3,
                        slidesOffsetAfter: 0,
                    }
                }
            });
        }
    }, 1500);

    if ($(".boutique_push_style_1 .wrapper_category_subcategory .swiper-slide").length > 3) {
        wrapper_category_subcategory_style1 = new Swiper(".boutique_push_style_1 .wrapper_category_subcategory", {
            slidesPerView: 3,
            navigation: {
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            },
        });
    }

    if ($(".boutique_push_style_2 .wrapper_category_subcategory .swiper-slide").length > 1) {
        wrapper_category_subcategory_style2 = new Swiper(".boutique_push_style_2 .wrapper_category_subcategory", {
            slidesPerView: 1,
            navigation: {
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            },
        });
    }

    if($('.wrapper_bandeau').length){
        $('.wrapper_bandeau .wrap_bg').css("opacity" , "1");
    }

    // bloc Shop

    var shop_bloc = $("#locatShop");
    var shop_bloc_lnk = $("#locatShop a");

    var shop_closed = function (evt) {
        $('#locatShop').hide();
        createCookie("shopHere", "closed", 1);
    };

    shop_bloc_lnk.on('click', function() {
        shop_bloc.hide();
        createCookie("shopHere", "closed", 1);
    });

    if (getCookie("shopHere") != "") {
        shop_bloc.remove();
    } else {
        $("body").append(shop_bloc);
        shop_bloc.show();
        shop_bloc.find('.close').on('click tap', shop_closed);
    }

    if($('#fab_swiper .swiper-slide').length > 1){
        var fabSwiper = new Swiper("#fab_swiper ", {
            slidesPerView: 1,
            autoplay: false,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        });

        fabSwiper.on('slideChangeTransitionEnd', function () {
            $('.fab_step .step').each(function() {
                if($(this).index() == fabSwiper.activeIndex){
                    $(this).find('.title').addClass('active');
                } else {
                    $(this).find('.title').removeClass('active');
                }
            });
        });

        $('.fab_step .step').each(function(idx) {
            var title = $(this).find('.title');

            title.on('click touch', function(){
                if($('.step .title').not(this).hasClass('active')){
                    $('.step .title').not(this).removeClass('active');
                    $(this).addClass('active');
                    fabSwiper.slideTo(idx);
                }
            });
        });
    }

    if($('.in_lookbook').length){
        $('.look_sliders').each(function(){
            if($(this).find('.swiper-slide').length > 1 && $(this).index() == 1){
                var nbrSlides = $(this).find('.swiper-slide').length;
                var lookSlider1 = new Swiper($(this), {
                    slidesPerView: 'auto',
                    loop: true,
                    loopedSlides : nbrSlides,
                    spaceBetween: 14,
                    direction: 'vertical',
                    speed: 6000,
                    autoplay: {
                        delay: 10
                    },
                });
            } else if($(this).find('.swiper-slide').length > 1 && $(this).index() == 2){
                var nbrSlides = $(this).find('.swiper-slide').length;
                var lookSlider2 = new Swiper($(this), {
                    slidesPerView: 'auto',
                    loop: true,
                    loopedSlides : nbrSlides,
                    spaceBetween: 14,
                    direction: 'vertical',
                    speed: 6000,
                    autoplay: {
                        delay: 10,
                        reverseDirection: true
                    },
                });
            }
        });
    }

    $('.tag').each(function(){
        //Show tags elements on mouse enter
        $(this).on('mouseenter', function(){
            $('.tag').not(this).find('.tag_tooltip').fadeOut(500);
            $('.tag').not($(this)).next('#shad_look').removeClass('actif');
            $('.tag').not(this).removeClass('hover');
            $(this).addClass('hover');
            $(this).find('.tag_tooltip').fadeIn(500);
            $(this).next('#shad_look').addClass('actif');
        });

        //Hide tags elements on mouse leave tooltip
        $(this).find('.tag_tooltip').on('mouseleave', function(){
            if($(this).parent().hasClass('hover')){
                $(this).fadeOut(500);
                $(this).parent().removeClass('hover');
                $(this).parent().next('#shad_look').removeClass('actif');
            }
        });
    });

    //trigger in vuejs submit button for Gift Card
    if($(".pageGiftCard #showAmount.discover-btn").length) {
        $(".pageGiftCard #showAmount.discover-btn").attr("onClick", "");
        $(".pageGiftCard #showAmount.discover-btn").on("click", function() {
            $(".container__input-code .w-submit-button:not(.w-loader)").click();
        });
    }

    $('.misc_cookiesinfo .content_sat .cookieShowLightbox').click(function() {
        $('.misc_cookiesinfo #cookiesLightbox').addClass('displayCookies');
        if($('.misc_cookiesinfo #cookiesLightbox').has('.hideCookies')) {
            $('.misc_cookiesinfo #cookiesLightbox').removeClass('hideCookies');
        }
        $('.misc_cookiesinfo #cookiesLightbox .w-form-line.w-submit button.w-loader').addClass('hideLoader');
    });

    $('.misc_cookiesinfo #js_cookie_refuse, .misc_cookiesinfo #js_cookie_accept').click(function() {
        if($('.misc_cookiesinfo #cookiesLightbox').has('.displayCookies')) {
            $('.misc_cookiesinfo #cookiesLightbox').removeClass('displayCookies');
            $('.misc_cookiesinfo #cookiesLightbox').addClass('hideCookies');
            $('.misc_cookiesinfo #shadCookie').removeClass('actif');
        }
    });
});

function createCookie(name, value, days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toGMTString();
    }
    document.cookie = name + "=" + value + expires + "; path=/";
}

function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1, c.length);
        }
        if (c.indexOf(nameEQ) == 0) {
            return c.substring(nameEQ.length, c.length);
        }
    }
    return null;
}

function eraseCookie(name) {
    createCookie(name, "", -1);
}

/**
 * Pour afficher le panier sur le coté (utilisé aussi dans product.js à l'ajout du panier)
 */
function topCartAppear() {
    var show_top_cart_parent = $('.my_basket');
    var count_bask = $('#cart_top .cart').attr('data-cart');

    if(count_bask > 2){
       // $("#cart_list").lionbars();
    }

    show_top_cart_parent.addClass("hover");
    $('#shad_menu').addClass("actif");
}

/**
 * Pour afficher le panier sur le coté (utilisé aussi dans product.js à l'ajout du panier)
 */
function topCartDesappear() {
    var show_top_cart_parent = $('.my_basket');
    show_top_cart_parent.removeClass("hover");
    $('#shad_menu').removeClass("actif");
}

function closeRecherche(){
    if($('.wrap_search_field').is(":visible")) {
        $('.wrap_search_field').fadeOut();
        $('#shad_menu').removeClass('actif');
    }
}

$('#search_home').keypress(function (e) {
    var key = e.which;

    if(key == 13 && $('#search_home').val().length >= 3){
        closeRecherche();
    }
});

function btnLoaderTrigger(evt) {

    "use strict";

    var element = $(this);
    var loader = $(this).next(".loader");

    if (loader.length > 0) {

        // Disable prevent.default on form in order to allow the submit
        if (!$(this).parents('form').length) {
            evt.preventDefault();
        }

        loader.removeAttr("style").parent().addClass("loading");
        loader.removeClass("cache");
        loader.addClass("loading");

        setTimeout(function () {
            loader.parent().removeClass("loading");
            loader.addClass("cache");
            loader.removeClass("loading");
        }, 1200);

        var href = $(this).attr("href");
        if (href && href != '#' && href != 'javascript:void(0)') {
            window.location.assign($(this).attr("href"));
        }

        // Dans le cas où il y a plusieurs bouton  en loading
        window.setTimeout(function () {
            $('.form_submit.loading').removeClass('loading')
        }, 5000);
    }
}

/**
 *	New functions (they are supposed to be at least a little bit cleaner than legacy code)
 */

// Below lies the legacy code (enter at your own risk)
function checkdate(d, m, y) {
    return (
        m > 0 &&
        m < 13 &&
        y > 0 &&
        y < 32768 &&
        d > 0 &&
        d <= new Date(y, m, 0).getDate()
    );
}
var nav_UA = window.navigator.userAgent.split("/"),
    nav_ver = parseFloat(nav_UA.pop()),
    nav_name = nav_UA.pop();

if (
    (nav_name.indexOf("safari") !== -1 && nav_ver <= 525) ||
    (nav_name.indexOf("firefox") !== -1 && nav_ver === 1.8)
) {
    function _dom_trackActiveElement(evt) {
        if (evt && evt.target) {
            document.activeElement = evt.target == document ? null : evt.target;
        }
    }
    function _dom_trackActiveElementLost(evt) {
        document.activeElement = null;
    }
    if (!document.activeElement) {
        document.addEventListener("focus", _dom_trackActiveElement, true);
        document.addEventListener("blur", _dom_trackActiveElementLost, true);
    }
}
/**/
var id_select = "";

// -----------------------------------------------------------------

// D�finit les classes initiales pour les elements d'un formulaire
function setInitialClassName(elementIdArray) {
    for (i = 0; i < elementIdArray.length; i++) {
        var elementInput = document.getElementById(elementIdArray[i]);
        var elementLabel = document.getElementById(elementIdArray[i] + "Label");
        if (elementInput && elementInput.type != "hidden") {
            elementInput.className = "inputForm";
            if (elementLabel) elementLabel.className = "labelForm";
        }
    }
}

// D�finit les classes initiales pour les elements de date d'un formulaire
function setInitialDateClassName(elementIdArray) {
    for (i = 0; i < elementIdArray.length; i++) {
        var elementInputDay = document.getElementById(elementIdArray[i] + "J");
        var elementInputMonth = document.getElementById(
            elementIdArray[i] + "M"
        );
        var elementInputYear = document.getElementById(elementIdArray[i] + "A");
        var elementLabel = document.getElementById(elementIdArray[i] + "Label");
        if (
            elementInputDay &&
            elementInputDay.type != "hidden" &&
            elementInputMonth &&
            elementInputMonth.type != "hidden" &&
            elementInputYear &&
            elementInputYear.type != "hidden"
        ) {
            elementInputDay.className = "inputForm";
            elementInputMonth.className = "inputForm";
            elementInputYear.className = "inputForm";
            if (elementLabel) elementLabel.className = "labelForm";
        }
    }
}

// V�rification d'un bouton radio
function verif_radio(elementID, fieldname) {
    if (
        elementID == "clientCiviliteM" ||
        elementID == "clientCiviliteMme" ||
        elementID == "clientCiviliteMlle"
    )
        elementID = "clientCivilite";

    if (
        elementID == "optin_y" ||
        elementID == "optin_n"
    )
        elementID = "optin";
    var objElement = document.getElementsByName(elementID);
    var objLabel = document.getElementById(elementID + "Label");
    var erreurText = document.getElementById("erreur_msg");
    var trouve = false;
    $(objElement)
        .removeData("data-error-text")
        .removeAttr("data-error-text");
    if (elementID) {
        $("input[name='" + elementID + "']").each(function(idx) {
            if ($(this).is(":checked") == true) {
                objElement.className = "inputForm";
                trouve = true;
            }
        });
        if (!trouve) {
            if (elementID == "clientCivilite") {
                t_error =
                    '<p class="title_toolltip">' +
                    ajax_file("ajax_sprintf.php?arg1=" + fieldname) +
                    " :</p>" +
                    ajax_file(
                        "ajax_sprintf.php?arg1=js_error_civilite&arg2=" +
                            fieldname
                    );
            } else {
                t_error =
                    '<p class="title_toolltip">' +
                    ajax_file("ajax_sprintf.php?arg1=" + fieldname) +
                    " :</p>" +
                    ajax_file(
                        "ajax_sprintf.php?arg1=js_error_thanks&arg2=" +
                            fieldname
                    );
            }

            $(objElement).data("data-error-text", t_error);
            erreurText.innerHTML = t_error;
            $(objElement).addClass("inputErr");
            $(objLabel).addClass("labelErr");
            return false;
        } else {
            $(objElement).removeClass("inputErr");
            $(objLabel).removeClass("labelErr");
        }
    }
    return true;
}

function verif_price(e, t) {
    var a = document.getElementById(e),
        r = document.getElementById(e + "Label"),
        l = (document.getElementById("mes_err_fieldset"),
        document.getElementById("erreur_msg"));
    if (
        ($(a)
            .removeData("data-error-text")
            .removeAttr("data-error-text"),
        "" == a.value)
    )
        return (
            (t_error =
                '<p class="title_toolltip">' +
                ajax_file("ajax_sprintf.php?arg1=" + t) +
                " :</p>" +
                ajax_file("ajax_sprintf.php?arg1=js_thanks_precise&arg2=" + t)),
            $(a).data("data-error-text", t_error),
            (l.innerHTML = t_error),
            (a.className = "inputErr"),
            (r.className = "labelErr"),
            !1
        );
    var n = validate_price(a.value);
    return 0 == n
        ? ((t_error =
              '<p class="title_toolltip">' +
              ajax_file("ajax_sprintf.php?arg1=" + t) +
              " :</p>" +
              ajax_file("ajax_sprintf.php?arg1=js_thanks_precise&arg2=" + t)),
          $(a).data("data-error-text", t_error),
          (l.innerHTML = t_error),
          (a.className = "inputErr"),
          (r.className = "labelErr"),
          !1)
        : ((a.className = "inputForm"), (r.className = "labelForm"), !0);
}

function validate_price(e) {
    return /^[\d\.,]+$/.test(e);
}

// V�rificartion d'une case coch�
function verif_checkbox(elementName, fieldname) {
    var objElement = document.getElementsByName(elementName);
    var objLabel = document.getElementById(elementName.slice(0, -2) + "Label");
    var erreurFieldset = document.getElementById("mes_err_fieldset");
    var erreurText = document.getElementById("erreur_msg");
    if (elementName) {
        objElement.className = "inputForm";
        objLabel.className = "labelForm";
        for (i = 0; i < objElement.length; i++)
            if (objElement[i].checked == true) {
                objElement.className = "inputForm";
                objLabel.className = "labelForm";
                return true;
            }
        erreurFieldset.style.display = "block";
        erreurText.innerHTML = ajax_file(
            "ajax_sprintf.php?arg1=js_error_thanks&arg2=" + fieldname
        );
        objElement.className = "inputErr";
        objLabel.className = "labelErr";
        return false;
    }
    return true;
}

function btnLoaderUnloading(btn) {

    "use strict";

    var loader = btn.next(".loader");

    if (loader.length > 0) {

        setTimeout(function(){loader.removeAttr("style").parent().removeClass("loading")}, 200);
    }
}

function verif_checkbox_index(elementName, fieldname, pIndex) {
    var erreurFieldset = document.getElementById("mes_err_fieldset");
    var erreurText = document.getElementById("erreur_msg");
    if (document.prodForm.visuStatutDetail1) {
        if (document.prodForm.visuStatutDetail1[0].checked == true) {
            return true;
        }
        erreurFieldset.style.display = "block";
        erreurText.innerHTML = ajax_file(
            "ajax_sprintf.php?arg1=js_error_photo_validate"
        );
        return false;
    } else {
        erreurFieldset.style.display = "block";
        erreurText.innerHTML = ajax_file(
            "ajax_sprintf.php?arg1=js_error_photo_validate"
        );
        return false;
    }
}

function verif_zip(elementZipID, elementCountryID, fieldname, nb) {
    var objZipElement = document.getElementById(elementZipID);
    var objCountryElement = document.getElementById(elementCountryID);
    var objLabel = document.getElementById(elementZipID + "Label");
    var erreurFieldset = document.getElementById("mes_err_fieldset");
    var erreurText = document.getElementById("erreur_msg");

    var idPays = objZipElement.value.substr(0, 2);

    if (objCountryElement.value == "DO" && idPays != "97") {
        erreurText.innerHTML =
            '<p class="title_toolltip">' +
            ajax_file(
                path_relative_root + "ajax_sprintf.php?arg1=" + fieldname
            ) +
            " :</p>" +
            ajax_file(
                path_relative_root +
                    "ajax_sprintf.php?arg1=js_error_zip_special_1&arg2=" +
                    fieldname +
                    "&arg3=97"
            );
        $(objZipElement).addClass("inputErr");
        $(objLabel).addClass("labelErr");
        return false;
    } else if (objCountryElement.value == "MO" && idPays != "98") {
        erreurText.innerHTML =
            '<p class="title_toolltip">' +
            ajax_file(
                path_relative_root + "ajax_sprintf.php?arg1=" + fieldname
            ) +
            " :</p>" +
            ajax_file(
                path_relative_root +
                    "ajax_sprintf.php?arg1=js_error_zip_special_1&arg2=" +
                    fieldname +
                    "&arg3=98"
            );
        $(objZipElement).addClass("inputErr");
        $(objLabel).addClass("labelErr");
        return false;
    } else if (
        objCountryElement.value == "TO" &&
        idPays != "97" &&
        idPays != "98"
    ) {
        erreurText.innerHTML =
            '<p class="title_toolltip">' +
            ajax_file(
                path_relative_root + "ajax_sprintf.php?arg1=" + fieldname
            ) +
            " :</p>" +
            ajax_file(
                path_relative_root +
                    "ajax_sprintf.php?arg1=js_error_zip_special_1&arg2=" +
                    fieldname +
                    "&arg3=97ou98"
            );
        $(objZipElement).addClass("inputErr");
        $(objLabel).addClass("labelErr");
        return false;
    } else if (
        objCountryElement.value != "DO" &&
        objCountryElement.value != "TO" &&
        objCountryElement.value != "MO" &&
        (idPays == "97" ||
            (idPays == "98" &&
                objCountryElement.value != "FR" &&
                objCountryElement.value != "US"))
    ) {
        erreurText.innerHTML =
            '<p class="title_toolltip">' +
            ajax_file(
                path_relative_root + "ajax_sprintf.php?arg1=" + fieldname
            ) +
            " :</p>" +
            ajax_file(
                path_relative_root +
                    "ajax_sprintf.php?arg1=js_error_zip_special_2&arg2=" +
                    fieldname +
                    "&arg3=" +
                    idPays
            );
        $(objZipElement).addClass("inputErr");
        $(objLabel).addClass("labelErr");
        return false;
    }

    $(objZipElement).removeClass("inputForm");
    $(objLabel).removeClass("labelErr");
    return true;
}

function verif_num(elementID, fieldname, nb) {
    var flg = 0;
    var num = "0123456789.,";
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + "Label");
    var erreurText = document.getElementById("erreur_msg");
    var point = 0;
    var virgule = 0;

    if (objElement.value == "") {
        erreurText.innerHTML =
            '<p class="title_toolltip">' +
            ajax_file(
                path_relative_root + "ajax_sprintf.php?arg1=" + fieldname
            ) +
            " :</p>" +
            ajax_file(
                path_relative_root +
                    "ajax_sprintf.php?arg1=js_thanks_precise&arg2=" +
                    fieldname
            );
        $(objElement).addClass("inputErr");
        $(objLabel).addClass("labelErr");

        return false;
    } else {
        if (objElement.value.length < nb) {
            erreurText.innerHTML =
                '<p class="title_toolltip">' +
                ajax_file(
                    path_relative_root + "ajax_sprintf.php?arg1=" + fieldname
                ) +
                " :</p>" +
                ajax_file(
                    path_relative_root +
                        "ajax_sprintf.php?arg1=js_at_least_x_digits&arg2=color:red;&arg3=" +
                        fieldname +
                        "&arg4=" +
                        nb
                );
            $(objElement).addClass("inputErr");
            $(objLabel).addClass("labelErr");
            return false;
        }
        for (var k = 0; k < objElement.value.length; k++) {
            var test = objElement.value.substring(k, k + 1);
            if (num.indexOf(test) < 0) flg++;
        }
        if (flg > 0) {
            erreurText.innerHTML =
                '<p class="title_toolltip">' +
                ajax_file(
                    path_relative_root + "ajax_sprintf.php?arg1=" + fieldname
                ) +
                " :</p>" +
                ajax_file(
                    path_relative_root +
                        "ajax_sprintf.php?arg1=js_wrong_input_num&arg2=" +
                        fieldname
                );
            $(objElement).addClass("inputErr");
            $(objLabel).addClass("labelErr");
            flg = 0;
            return false;
        }
        for (var k = 0; k < objElement.value.length; k++) {
            var test = objElement.value.substring(k, k + 1);
            if (test == ".") {
                point = point + 1;
            }
            if (test == ",") {
                virgule = virgule + 1;
            }
        }
        var tot = point + virgule;
        if (point > 1 || virgule > 1 || tot > 1) {
            erreurText.innerHTML = erreurText.innerHTML =
                '<p class="title_toolltip">' +
                ajax_file(
                    path_relative_root + "ajax_sprintf.php?arg1=" + fieldname
                ) +
                " :</p>" +
                ajax_file(
                    path_relative_root +
                        "ajax_sprintf.php?arg1=js_wrong_input_num&arg2=" +
                        fieldname
                );
            $(objElement).addClass("inputErr");
            $(objLabel).addClass("labelErr");
            flg = 0;
            return false;
        }
    }
    $(objElement).removeClass("inputErr");
    $(objElement).removeClass("labelErr");
    return true;
}

function verif_portable(elementID, fieldname) {
    return verif_telephone(elementID, fieldname, true);
}

function verif_telephone(elementID, fieldname, is_mobile) {
    var objElementHidden = document.getElementById(elementID + "Hidden");
    var objElement = document.getElementById(elementID);
    var erreurText = document.getElementById("erreur_msg");

    if (objElementHidden !== null) {
        var value = $(objElement).intlTelInput("getNumber");
        var type = $(objElement).intlTelInput("getNumberType");

        if (value.length == 12 && value.substr(1, 3) == "337") {
            if (elementID === "clientTel") {
                $("#clientMobileHidden").val(value);
            } else if (elementID == "telephone") {
                $("#mobileHidden").val(value);
            } else if (elementID == "billtelephone") {
                $("#billmobileHidden").val(value);
            } else if (elementID == "candidatTel") {
                $("#candidatTelHidden").val(value);
            }
        } else {
            if (
                $(objElement).intlTelInput("isValidNumber") !== false &&
                (type === 0 || type === 2 || type === 3 || type === 6)
            ) {
                $(objElementHidden).val(value);
            } else if (
                $(objElement).intlTelInput("isValidNumber") !== false &&
                type === 1
            ) {
                if (elementID === "clientTel") {
                    $("#clientMobileHidden").val(value);
                } else if (elementID == "telephone") {
                    $("#mobileHidden").val(value);
                } else if (elementID == "billtelephone") {
                    $("#billmobileHidden").val(value);
                } else if (elementID == "candidatTel") {
                    $("#candidatTelHidden").val(value);
                }
            }
        }

        if ($(objElement).intlTelInput("isValidNumber") === false) {
            $(objElement).addClass("inputErr");

            return false;
        }

        $(objElement).removeClass("inputErr");

        return true;
    }

    if ($.trim($(objElement).val()).length > 0) {
        if (
            $(objElement).intlTelInput("isValidNumber") === false ||
            (is_mobile && $(objElement).intlTelInput("getNumberType") === 0) ||
            (is_mobile == false &&
                $(objElement).intlTelInput("getNumberType") === 1)
        ) {
            if (
                is_mobile &&
                $(objElement).intlTelInput("getNumberType") === 0
            ) {
                erreurText.innerHTML =
                    '<p class="title_toolltip">' +
                    ajax_file(
                        "ajax_sprintf.php?arg2=" + fieldname + "_mobile"
                    ) +
                    " :</p>" +
                    ajax_file(
                        "ajax_sprintf.php?arg1=js_error_mobile&arg2=" +
                            fieldname
                    );
            } else {
                erreurText.innerHTML =
                    '<p class="title_toolltip">' +
                    ajax_file("ajax_sprintf.php?arg2=" + fieldname) +
                    " :</p>" +
                    ajax_file(
                        "ajax_sprintf.php?arg1=js_error_phone&arg2=" + fieldname
                    );
            }

            return false;
        } else {
            $(objElementHidden).val($(objElement).intlTelInput("getNumber"));
        }

        return true;
    }

    $(objElement).addClass("inputErr");

    return false;
}

function verif_ref_interne(elementID, fieldname) {
    var flg = 0;
    var alphanum =
        "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + "Label");
    var erreurFieldset = document.getElementById("mes_err_fieldset");
    var erreurText = document.getElementById("erreur_msg");
    if (objElement.value == "") {
        erreurFieldset.style.display = "block";
        erreurText.innerHTML = ajax_file(
            "ajax_sprintf.php?arg1=js_error_thanks&arg2=" + fieldname
        );
        objElement.className = "inputErr";
        objLabel.className = "labelErr";
        objElement.focus();
        return false;
    } else {
        var space_after = objElement.value.lastIndexOf(" ");
        var space_before = objElement.value.indexOf(" ");
        if (
            (space_after + 1 == objElement.value.length ||
                space_before + 1 == 1) &&
            objElement.value.length <= 9
        ) {
            erreurFieldset.style.display = "block";
            erreurText.innerHTML = ajax_file(
                "ajax_sprintf.php?arg1=js_at_least_n_chars_space&arg2=" +
                    fieldname +
                    "&arg3=9"
            );
            objElement.className = "inputErr";
            objLabel.className = "labelErr";
            objElement.focus();
            return false;
        }
        if (objElement.value.length < 9) {
            erreurFieldset.style.display = "block";
            erreurText.innerHTML = ajax_file(
                "ajax_sprintf.php?arg1=js_at_least_n_chars&arg2=9&arg3=" +
                    fieldname
            );
            objElement.className = "inputErr";
            objLabel.className = "labelErr";
            objElement.focus();
            return false;
        }
        if (objElement.value.length > 14) {
            erreurFieldset.style.display = "block";
            erreurText.innerHTML = ajax_file(
                "ajax_sprintf.php?arg1=js_error_ref_14_chars&arg2=" + fieldname
            );
            objElement.className = "inputErr";
            objLabel.className = "labelErr";
            objElement.focus();
            return false;
        }
        for (var k = 0; k < objElement.value.length; k++) {
            var test = objElement.value.substring(k, k + 1);

            if (alphanum.indexOf(test) < 0) flg++;
        }
        if (flg > 0) {
            erreurFieldset.style.display = "block";
            erreurText.innerHTML = ajax_file(
                "ajax_sprintf.php?arg1=js_wrong_input_alphanum&arg2=" +
                    fieldname
            );
            objElement.className = "inputErr";
            objLabel.className = "labelErr";
            objElement.focus();
            flg = 0;
            return false;
        }
    }
    objElement.className = "inputForm";
    objLabel.className = "labelForm";
    return true;
}

function verif_alphanum_codepromo(elementID, fieldname) {
    var flg = 0;
    var alphanum =
        "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ,-_/.<>()%:;";
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + "Label");
    var erreurFieldset = document.getElementById("mes_err_fieldset");
    var erreurText = document.getElementById("erreur_msg");
    if (objElement.value == "") {
        erreurFieldset.style.display = "block";
        erreurText.innerHTML = ajax_file(
            "ajax_sprintf.php?arg1=js_error_thanks&arg2=" + fieldname
        );
        objElement.className = "inputErr";
        objLabel.className = "labelErr";
        objElement.focus();
        return false;
    } else {
        var space_after = objElement.value.lastIndexOf(" ");
        var space_before = objElement.value.indexOf(" ");
        if (
            (space_after + 1 == objElement.value.length ||
                space_before + 1 == 1) &&
            objElement.value.length <= 4
        ) {
            erreurFieldset.style.display = "block";
            erreurText.innerHTML = ajax_file(
                "ajax_sprintf.php?arg1=js_at_least_n_chars_space&arg2=" +
                    fieldname +
                    "&arg3=4"
            );
            objElement.className = "inputErr";
            objLabel.className = "labelErr";
            objElement.focus();
            return false;
        }
        if (objElement.value.length < 4) {
            erreurFieldset.style.display = "block";
            erreurText.innerHTML = ajax_file(
                "ajax_sprintf.php?arg1=js_at_least_n_chars&arg2=4&arg3=" +
                    fieldname
            );
            objElement.className = "inputErr";
            objLabel.className = "labelErr";
            objElement.focus();
            return false;
        }
        for (var k = 0; k < objElement.value.length; k++) {
            var test = objElement.value.substring(k, k + 1);

            if (alphanum.indexOf(test) < 0) flg++;
        }
        if (flg > 0) {
            erreurFieldset.style.display = "block";
            erreurText.innerHTML = ajax_file(
                "ajax_sprintf.php?arg1=js_wrong_input_alphanum&arg2=" +
                    fieldname
            );
            objElement.className = "inputErr";
            objLabel.className = "labelErr";
            objElement.focus();
            flg = 0;
            return false;
        }
    }
    objElement.className = "inputForm";
    objLabel.className = "labelForm";
    return true;
}

function verif_empty(elementID, fieldname) {
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + "Label");
    var erreurText = document.getElementById("erreur_msg");
    $(objElement)
        .removeData("data-error-text")
        .removeAttr("data-error-text");
    if (objElement.value == "") {
        t_error =
            '<p class="title_toolltip">' +
            ajax_file("ajax_sprintf.php?arg1=" + fieldname) +
            " :</p>" +
            ajax_file(
                "ajax_sprintf.php?arg1=js_thanks_precise&arg2=" + fieldname
            );
        erreurText.innerHTML = t_error;
        $(objElement).data("data-error-text", t_error);
        $(objElement).addClass("inputErr");
        $(objLabel).addClass("labelErr");
        $(objElement).removeClass("inputForm");
        $(objLabel).removeClass("labelForm");
        return false;
    }
    $(objElement).addClass("inputForm");
    $(objLabel).addClass("labelForm");
    $(objElement).removeClass("inputErr");
    $(objLabel).removeClass("labelErr");
    return true;
}

function verif_alphanum(elementID, fieldname, isMin, length) {
    var flg = 0;
    var alphanum =
        decodeURIComponent("%C2%B0") +
        "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ,'\"-_/.<>\n\r()%:; " +
        decodeURIComponent(
            "%C3%A2%C3%A0%C3%A4%C3%A9%C3%A8%C3%AB%C3%AA%C3%AC%C3%AF%C3%AE%C3%B6%C3%B4%C3%B2%C3%B9%C3%BC%C3%BB%C3%A7&%C3%84%C3%82%C3%80%C3%89%C3%88%C3%8B%C3%8A%C3%8C%C3%8F%C3%8E%C3%96%C3%94%C3%92%C3%99%C3%9C%C3%9B%C3%87"
        );
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + "Label");
    var erreurText = document.getElementById("erreur_msg");
    $(objElement)
        .removeData("data-error-text")
        .removeAttr("data-error-text");
    if (objElement.value == "") {
        t_error =
            '<p class="title_toolltip">' +
            ajax_file("ajax_sprintf.php?arg1=" + fieldname) +
            " :</p>" +
            ajax_file(
                "ajax_sprintf.php?arg1=js_thanks_precise&arg2=" + fieldname
            );
        $(objElement).data("data-error-text", t_error);
        erreurText.innerHTML = t_error;
        $(objElement).addClass("inputErr");
        $(objLabel).addClass("labelErr");
        return false;
    } else {
        var space_after = objElement.value.lastIndexOf(" ");
        var space_before = objElement.value.indexOf(" ");
        if (
            (space_after + 1 == objElement.value.length ||
                space_before + 1 == 1) &&
            objElement.value.length <= 3
        ) {
            t_error =
                '<p class="title_toolltip">' +
                ajax_file("ajax_sprintf.php?arg1=" + fieldname) +
                " :</p>" +
                ajax_file(
                    "ajax_sprintf.php?arg1=js_at_least_n_chars_space&arg2=" +
                        fieldname +
                        "&arg3=3"
                );
            $(objElement).data("data-error-text", t_error);
            erreurText.innerHTML = t_error;
            $(objElement).addClass("inputErr");
            $(objLabel).addClass("labelErr");
            return false;
        }
        if (isMin == false && objElement.value.length < 3) {
            t_error =
                '<p class="title_toolltip">' +
                ajax_file("ajax_sprintf.php?arg1=" + fieldname) +
                " :</p>" +
                ajax_file(
                    "ajax_sprintf.php?arg1=js_at_least_n_chars&arg2=3&arg3=" +
                        fieldname
                );
            $(objElement).data("data-error-text", t_error);
            erreurText.innerHTML = t_error;
            $(objElement).addClass("inputErr");
            $(objLabel).addClass("labelErr");
            return false;
        }
        for (var k = 0; k < objElement.value.length; k++) {
            var test = objElement.value.substring(k, k + 1);

            if (alphanum.indexOf(test) < 0) {
                flg++;
            }
        }
        if (flg > 0) {
            t_error =
                '<p class="title_toolltip">' +
                ajax_file("ajax_sprintf.php?arg1=" + fieldname) +
                " :</p>" +
                ajax_file(
                    "ajax_sprintf.php?arg1=js_wrong_input_alphanum&arg2=" +
                        fieldname
                );
            $(objElement).data("data-error-text", t_error);
            erreurText.innerHTML = t_error;
            $(objElement).addClass("inputErr");
            $(objLabel).addClass("labelErr");
            flg = 0;
            return false;
        }
        if (isMin == true && objElement.value.length > length) {
            t_error =
                '<p class="title_toolltip">' +
                ajax_file("ajax_sprintf.php?arg1=" + fieldname) +
                " :</p>" +
                ajax_file(
                    "ajax_sprintf.php?arg1=js_too_long_alphanum&arg2=" +
                        fieldname +
                        "&arg3=" +
                        length
                );
            $(objElement).data("data-error-text", t_error);
            erreurText.innerHTML = t_error;
            $(objElement).addClass("inputErr");
            $(objLabel).addClass("labelErr");
            return false;
        }
    }
    $(objElement).removeClass("inputErr");
    $(objLabel).removeClass("labelErr");
    return true;
}

function verif_alphanumadresse(elementID, fieldname, isMin, length) {
    var flg = 0;
    var alphanum =
        "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ,'\"_/.<>\n\r()%:; " +
        decodeURIComponent(
            "%C3%A2%C3%A0%C3%A9%C3%A8%C3%AB%C3%AAi%C3%AF%C3%AE%C3%B6%C3%B4%C3%B9%C3%BC%C3%BB%C3%A7%26%C3%84%C3%82%C3%80%C3%89%C3%88%C3%8B%C3%8AI%C3%8F%C3%8E%C3%96%C3%94%C3%99%C3%9C%C3%9B%C3%87%3F!%E2%82%AC-"
        );
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + "Label");
    var erreurText = document.getElementById("erreur_msg");
    $(objElement)
        .removeData("data-error-text")
        .removeAttr("data-error-text");
    if (objElement.value == "") {
        t_error =
            '<p class="title_toolltip">' +
            ajax_file(
                path_relative_root + "ajax_sprintf.php?arg1=" + fieldname
            ) +
            " :</p>" +
            ajax_file(
                path_relative_root +
                    "ajax_sprintf.php?arg1=js_thanks_precise&arg2=" +
                    fieldname
            );
        $(objElement).data("data-error-text", t_error);
        erreurText.innerHTML = t_error;
        objElement.className = "inputErr";
        if (objLabel) objLabel.className = "labelErr";
        return false;
    } else {
        var space_after = objElement.value.lastIndexOf(" ");
        var space_before = objElement.value.indexOf(" ");
        if (
            (space_after + 1 == objElement.value.length ||
                space_before + 1 == 1) &&
            objElement.value.length <= 3
        ) {
            t_error =
                '<p class="title_toolltip">' +
                ajax_file(
                    path_relative_root + "ajax_sprintf.php?arg1=" + fieldname
                ) +
                " :</p>" +
                ajax_file(
                    path_relative_root +
                        "ajax_sprintf.php?arg1=js_at_least_n_chars_space&arg2=" +
                        fieldname +
                        "&arg3=3"
                );
            $(objElement).data("data-error-text", t_error);
            erreurText.innerHTML = t_error;
            objElement.className = "inputErr";
            if (objLabel) objLabel.className = "labelErr";
            return false;
        }
        if (isMin == false && objElement.value.length < 3) {
            t_error =
                '<p class="title_toolltip">' +
                ajax_file(
                    path_relative_root + "ajax_sprintf.php?arg1=" + fieldname
                ) +
                " :</p>" +
                ajax_file(
                    path_relative_root +
                        "ajax_sprintf.php?arg1=js_at_least_n_chars&arg2=3&arg3=" +
                        fieldname
                );
            $(objElement).data("data-error-text", t_error);
            erreurText.innerHTML = t_error;
            objElement.className = "inputErr";
            if (objLabel) objLabel.className = "labelErr";
            return false;
        }
        for (var k = 0; k < objElement.value.length; k++) {
            var test = objElement.value.substring(k, k + 1);

            if (alphanum.indexOf(test) < 0) flg++;
        }
        if (flg > 0) {
            t_error =
                '<p class="title_toolltip">' +
                ajax_file(
                    path_relative_root + "ajax_sprintf.php?arg1=" + fieldname
                ) +
                " :</p>" +
                ajax_file(
                    path_relative_root +
                        "ajax_sprintf.php?arg1=js_wrong_input_alphanum&arg2=" +
                        fieldname
                );
            $(objElement).data("data-error-text", t_error);
            erreurText.innerHTML = t_error;
            objElement.className = "inputErr";
            if (objLabel) objLabel.className = "labelErr";
            flg = 0;
            return false;
        }
        if (isMin == true && objElement.value.length > length) {
            t_error =
                '<p class="title_toolltip">' +
                ajax_file(
                    path_relative_root + "ajax_sprintf.php?arg1=" + fieldname
                ) +
                " :</p>" +
                ajax_file(
                    path_relative_root +
                        "ajax_sprintf.php?arg1=js_too_long_alphanum&arg2=" +
                        fieldname +
                        "&arg3=" +
                        length
                );
            $(objElement).data("data-error-text", t_error);
            erreurText.innerHTML = t_error;
            objElement.className = "inputErr";
            objLabel.className = "labelErr";
            return false;
        }
    }
    objElement.className = "inputForm";
    if (objLabel) objLabel.className = "labelForm";
    return true;
}

function verif_textarea(elementID, fieldname) {
    var flg = 0;
    var alphanum =
        "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ,'\"\\n\r-_/.()%:; �����i�������";
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + "Label");
    var erreurFieldset = document.getElementById("mes_err_fieldset");
    var erreurText = document.getElementById("erreur_msg");
    if (objElement.value == "") {
        erreurFieldset.style.display = "block";
        erreurText.innerHTML = ajax_file(
            "ajax_sprintf.php?arg1=js_error_thanks&arg2=" + fieldname
        );
        objElement.className = "textareaErr";
        objLabel.className = "labelErr";
        objElement.focus();
        return false;
    } else {
        var space_after = objElement.value.lastIndexOf(" ");
        var space_before = objElement.value.indexOf(" ");
        if (
            (space_after + 1 == objElement.value.length ||
                space_before + 1 == 1) &&
            objElement.value.length <= 3
        ) {
            erreurFieldset.style.display = "block";
            erreurText.innerHTML = ajax_file(
                "ajax_sprintf.php?arg1=js_at_least_n_chars_space&arg2=" +
                    fieldname +
                    "&arg3=3"
            );
            objElement.className = "textareaErr";
            objLabel.className = "labelErr";
            objElement.focus();
            return false;
        }
        if (objElement.value.length < 3) {
            erreurFieldset.style.display = "block";
            erreurText.innerHTML = ajax_file(
                "ajax_sprintf.php?arg1=js_at_least_n_chars&arg2=3&arg3=" +
                    fieldname
            );
            objElement.className = "textareaErr";
            objLabel.className = "labelErr";
            objElement.focus();
            return false;
        }
        for (var k = 0; k < objElement.value.length; k++) {
            var test = objElement.value.substring(k, k + 1);
            if (alphanum.indexOf(test) < 0) flg++;
        }
        if (flg > 0) {
            erreurFieldset.style.display = "block";
            erreurText.innerHTML = ajax_file(
                "ajax_sprintf.php?arg1=js_wrong_input_alphanum&arg2=" +
                    fieldname
            );
            objElement.className = "textareaErr";
            objLabel.className = "labelErr";
            objElement.focus();
            flg = 0;
            return false;
        }
    }
    objElement.className = "textarea";
    objLabel.className = "labelForm";
    return true;
}

function verif_textarea2(elementID, fieldname) {
    var flg = 0;
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + "Label");
    var erreurFieldset = document.getElementById("mes_err_fieldset");
    var erreurText = document.getElementById("erreur_msg");
    if (objElement.value == "") {
        erreurFieldset.style.display = "block";
        erreurText.innerHTML = ajax_file(
            "ajax_sprintf.php?arg1=js_error_thanks&arg2=" + fieldname
        );
        objElement.className = "textareaErr";
        objLabel.className = "labelErr";
        return false;
    }
    if (objElement.value.length < 3) {
        erreurFieldset.style.display = "block";
        erreurText.innerHTML = ajax_file(
            "ajax_sprintf.php?arg1=js_at_least_n_chars&arg2=3&arg3=" + fieldname
        );
        objElement.className = "textareaErr";
        objLabel.className = "labelErr";
        return false;
    }
    objElement.className = "textarea";
    objLabel.className = "labelForm";
    return true;
}

function verif_mail(elementID, fieldname, is_spe) {
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + "Label");
    var erreurText = document.getElementById("erreur_msg");
    var mail = new RegExp(
        "^[a-z0-9_-]+([.]?[a-z0-9_-]{1,})*@([a-z0-9_-]{2,}[.])+[a-z]{2,4}$",
        "gi"
    );

    path = "";
    if (typeof path_relative_root != "undefined") path += path_relative_root;

    if (mail.exec(objElement.value) == null) {
        if (typeof is_spe == "undefined") {
            var translations = ajax_file(
                path +
                    "ajax_sprintf.php?arg0=%s|%s" +
                    "&arg1=" +
                    fieldname +
                    "&arg2=js_wrong_input_mail"
            );
            translations = translations.split("|");
            type_champ = translations[0];
            type_message = translations[1];

            erreurText.innerHTML =
                '<p class="title_toolltip">' +
                type_champ +
                " :</p>" +
                type_message;
        }
        $(objElement).addClass("inputErr");
        $(objLabel).addClass("labelErr");
        return false;
    }
    $(objElement).removeClass("inputErr");
    $(objLabel).removeClass("labelErr");
    return true;
}

function verif_alpha(elementID, fieldname) {
    var flg = 0;
    var alphanum =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ,'\"-_/.ééÈèËëÊêÔôÎîÛûÂâÏïÄÖöäÜüÀàÙùÇç&n " +
        decodeURIComponent(
            "%C3%A0%C3%A9%C3%A8%C3%AB%C3%AAi%C3%AF%C3%AE%C3%B6%C3%B4%C3%B9%C3%BC%C3%BB%C3%A7%C3%A4%C3%84%C3%82%C3%80%C3%89%C3%88%C3%8B%C3%8AI%C3%8F%C3%8E%C3%96%C3%94%C3%99%C3%9C%C3%9B%C3%87%C2%A0 "
        );
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + "Label");
    var erreurText = document.getElementById("erreur_msg");
    $(objElement)
        .removeData("data-error-text")
        .removeAttr("data-error-text");
    if (objElement.value == "") {
        t_error =
            '<p class="title_toolltip">' +
            ajax_file("ajax_sprintf.php?arg1=" + fieldname) +
            " :</p>" +
            ajax_file(
                "ajax_sprintf.php?arg1=js_thanks_precise&arg2=" + fieldname
            );
        $(objElement).data("data-error-text", t_error);
        erreurText.innerHTML = t_error;
        $(objElement).addClass("inputErr");
        $(objLabel).addClass("labelErr");
        return false;
    } else {
        var space_after = objElement.value.lastIndexOf(" ");
        var space_before = objElement.value.indexOf(" ");
        if (
            (space_after + 1 == objElement.value.length ||
                space_before + 1 == 1) &&
            objElement.value.length <= 2
        ) {
            t_error =
                '<p class="title_toolltip">' +
                ajax_file("ajax_sprintf.php?arg1=" + fieldname) +
                " :</p>" +
                ajax_file(
                    "ajax_sprintf.php?arg1=js_at_least_n_chars_space&arg2=" +
                        fieldname +
                        "&arg3=2"
                );
            $(objElement).data("data-error-text", t_error);
            erreurText.innerHTML = t_error;
            $(objElement).addClass("inputErr");
            $(objLabel).addClass("labelErr");
            return false;
        }
        if (objElement.value.length < 2) {
            t_error =
                '<p class="title_toolltip">' +
                ajax_file("ajax_sprintf.php?arg1=" + fieldname) +
                " :</p>" +
                ajax_file(
                    "ajax_sprintf.php?arg1=js_at_least_n_chars&arg2=2&arg3=" +
                        fieldname
                );
            $(objElement).data("data-error-text", t_error);
            erreurText.innerHTML = t_error;
            $(objElement).addClass("inputErr");
            $(objLabel).addClass("labelErr");
            return false;
        }
        for (var k = 0; k < objElement.value.length; k++) {
            var test = objElement.value.substring(k, k + 1);
            if (alphanum.indexOf(test) < 0) flg++;
        }
        if (flg > 0) {
            t_error =
                '<p class="title_toolltip">' +
                ajax_file("ajax_sprintf.php?arg1=" + fieldname) +
                " :</p>" +
                ajax_file(
                    "ajax_sprintf.php?arg1=js_wrong_input_alpha&arg2=" +
                        fieldname
                );
            $(objElement).data("data-error-text", t_error);
            erreurText.innerHTML = t_error;
            $(objElement).addClass("inputErr");
            $(objLabel).addClass("labelErr");
            flg = 0;
            return false;
        }
    }
    $(objElement).removeClass("inputErr");
    $(objLabel).removeClass("labelErr");
    return true;
}

function verif_format_date(elementID, fieldname) {
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + "Label");
    var erreurFieldset = document.getElementById("mes_err_fieldset");
    var erreurText = document.getElementById("erreur_msg");
    if (objElement.value != "") {
        var regDateVente = new RegExp("^(\\d{2})/(\\d{2})/(\\d{2})$", "gi");
        if (regDateVente.exec(objElement.value) == null) {
            erreurFieldset.style.display = "block";
            erreurText.innerHTML = ajax_file(
                "ajax_sprintf.php?arg1=js_error_date_1&arg2=" + fieldname
            );
            objElement.className = "inputErr";
            objLabel.className = "labelErr";
            return false;
        }
    } else {
        erreurFieldset.style.display = "block";
        erreurText.innerHTML = ajax_file(
            "ajax_sprintf.php?arg1=js_error_date_2&arg2=" + fieldname
        );
        objElement.className = "inputErr";
        objLabel.className = "labelErr";
        return false;
    }
    objElement.className = "inputForm";
    objLabel.className = "labelForm";
    return true;
}

function verif_format_date2(elementID, fieldname) {
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + "Label");
    var erreurFieldset = document.getElementById("mes_err_fieldset");
    var erreurText = document.getElementById("erreur_msg");
    if (objElement.value != "") {
        var regDateVente = new RegExp("^(\\d{2})/(\\d{2})/(\\d{4})$", "gi");
        if (regDateVente.exec(objElement.value) == null) {
            erreurFieldset.style.display = "block";
            erreurText.innerHTML = ajax_file(
                "ajax_sprintf.php?arg1=js_error_date_1&arg2=" + fieldname
            );
            objElement.className = "inputErr";
            objLabel.className = "labelErr";
            return false;
        }
    } else {
        erreurFieldset.style.display = "block";
        erreurText.innerHTML = ajax_file(
            "ajax_sprintf.php?arg1=js_error_date_2&arg2=" + fieldname
        );
        objElement.className = "inputErr";
        objLabel.className = "labelErr";
        return false;
    }
    objElement.className = "inputForm";
    objLabel.className = "labelForm";
    return true;
}

function verif_format_date_select(elementID, fieldname) {
    var objElementJ = document.getElementById(elementID + "J");
    var objElementM = document.getElementById(elementID + "M");
    var objElementA = document.getElementById(elementID + "A");
    var objLabel = document.getElementById(elementID + "Label");
    var erreurFieldset = document.getElementById("mes_err_fieldset");
    var erreurText = document.getElementById("erreur_msg");
    if (
        objElementJ.value == "" ||
        objElementM.value == "" ||
        objElementA.value == ""
    ) {
        erreurFieldset.style.display = "block";
        erreurText.innerHTML = ajax_file(
            "ajax_sprintf.php?arg1=js_thanks_precise&arg2=" + fieldname
        );
        objElementJ.className = "inputErr";
        objElementM.className = "inputErr";
        objElementA.className = "inputErr";
        objLabel.className = "labelErr";
        return false;
    }
    objElementJ.className = "inputForm";
    objElementM.className = "inputForm";
    objElementA.className = "inputForm";
    objLabel.className = "labelForm";
    return true;
}

function verif_format_majorite(elementID, fieldname) {
    var objElementJ = document.getElementById(elementID + "J");
    var objElementM = document.getElementById(elementID + "M");
    var objElementA = document.getElementById(elementID + "A");
    var date = new Date();
    var annee = date.getFullYear();
    var objLabel = document.getElementById(elementID + "Label");
    var erreurFieldset = document.getElementById("mes_err_fieldset");
    var erreurText = document.getElementById("erreur_msg");
    if (objElementA.value > annee - 18) {
        erreurFieldset.style.display = "block";
        erreurText.innerHTML = ajax_file(
            "ajax_sprintf.php?arg1=js_error_majorite"
        );
        objElementJ.className = "inputErr";
        objElementM.className = "inputErr";
        objElementA.className = "inputErr";
        objLabel.className = "labelErr";
        return false;
    }
    objElementJ.className = "inputForm";
    objElementM.className = "inputForm";
    objElementA.className = "inputForm";
    objLabel.className = "labelForm";
    return true;
}

function verif_selected(elementID, fieldname) {
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + "Label");
    var erreurText = document.getElementById("erreur_msg");
    if (objElement) {
        var objLabel = document.getElementById(elementID + "Label");
        if (objElement.value == "") {
            erreurText.innerHTML = ajax_file(
                "ajax_sprintf.php?arg1=js_error_select&arg2=" + fieldname
            );
            objElement.className = "inputErr";
            objLabel.className = "labelErr";
            return false;
        } else {
            objElement.className = "inputForm";
            objLabel.className = "labelForm";
            return true;
        }
    }
    return true;
}

function verif_selected_date(elementID, fieldname) {
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + "Label");
    var erreurText = document.getElementById("erreur_msg");
    var objElementJ = document.getElementById(elementID + "J");
    var objElementM = document.getElementById(elementID + "M");
    var objElementA = document.getElementById(elementID + "A");
    if (objElementJ && objElementM && objElementA) {
        erreurText.innerHTML =
            '<p class="title_toolltip">' +
            ajax_file("ajax_sprintf.php?arg1=" + fieldname) +
            " :</p>" +
            ajax_file(
                "ajax_sprintf.php?arg1=js_thanks_precise&arg2=" + fieldname
            );
        var error = false;
        if (objElementJ.value == "") {
            error = true;
            $(objElementJ).addClass("inputErr");
        } else {
            $(objElementJ).removeClass("inputErr");
        }
        if (objElementM.value == "") {
            error = true;
            $(objElementM).addClass("inputErr");
        } else {
            $(objElementM).removeClass("inputErr");
        }
        if (objElementA.value == "") {
            error = true;
            $(objElementA).addClass("inputErr");
        } else {
            $(objElementA).removeClass("inputErr");
        }
        if (!error) {
            $(objLabel).removeClass("labelErr");
            return true;
        } else {
            $(objLabel).addClass("labelErr");
            return false;
        }
    }
    return true;
}

function verif_selected2(elementID, fieldname) {
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + "Label");
    var erreurFieldset = document.getElementById("mes_err_fieldset");
    var erreurText = document.getElementById("erreur_msg");
    if (objElement) {
        var objLabel = document.getElementById(elementID + "Label");
        if (objElement.value == "") {
            erreurFieldset.style.display = "block";
            erreurText.innerHTML = ajax_file(
                "ajax_sprintf.php?arg1=js_error_select&arg2=" + fieldname
            );
            objElement.className = "inputErr";
            objLabel.className = "labelErr";
            return false;
        } else {
            objElement.className = "inputForm";
            objLabel.className = "labelForm";
            return true;
        }
    } else {
        erreurFieldset.style.display = "block";
        erreurText.innerHTML = ajax_file(
            "ajax_sprintf.php?arg1=js_error_select&arg2=" + fieldname
        );
        return false;
    }
    return true;
}

function verif_categ(formID) {
    var objForm = document.getElementById(formID);
    var erreurFieldset = document.getElementById("mes_err_fieldset");
    var erreurText = document.getElementById("erreur_msg");
    if (objForm.prodCadeau) {
        if (
            objForm.prodCadeau.checked == false &&
            !objForm.prodCateg_0.selectedIndex
        ) {
            erreurFieldset.style.display = "block";
            erreurText.innerHTML = ajax_file(
                "ajax_sprintf.php?arg1=js_error_categ_1"
            );
            return false;
        }
        if (
            objForm.prodCadeau.checked == false &&
            objForm.prodCateg_0.selectedIndex &&
            !objForm.prodSousCateg_0.selectedIndex
        ) {
            erreurFieldset.style.display = "block";
            erreurText.innerHTML = ajax_file(
                "ajax_sprintf.php?arg1=js_error_categ_1"
            );
            return false;
        }
        if (
            objForm.prodCadeau.checked == false &&
            objForm.prodCateg_0.selectedIndex &&
            objForm.prodSousCateg_0.selectedIndex &&
            !objForm.prodObjet_0.selectedIndex
        ) {
            erreurFieldset.style.display = "block";
            erreurText.innerHTML = ajax_file(
                "ajax_sprintf.php?arg1=js_error_categ_1"
            );
            return false;
        }
    } else {
        if (!objForm.prodCateg_0.selectedIndex) {
            erreurFieldset.style.display = "block";
            erreurText.innerHTML = ajax_file(
                "ajax_sprintf.php?arg1=js_error_categ_2"
            );
            return false;
        }
        $errTMP = false;
        for (x = 0; x <= 4; x++) {
            if (eval("objForm.prodCateg_" + x + ".selectedIndex"))
                if (!eval("objForm.prodObjet_" + x)) $errTMP = true;
                else if (!eval("objForm.prodObjet_" + x + ".selectedIndex"))
                    $errTMP = true;
        }
        if ($errTMP == true) {
            erreurFieldset.style.display = "block";
            erreurText.innerHTML = ajax_file(
                "ajax_sprintf.php?arg1=js_error_categ_3"
            );
            return false;
        }
    }
    return true;
}

var indexImg = 0;
function nextImage(elementID, imgPath) {
    if (imgPath.length > 0) {
        indexImg++;
        if (indexImg >= imgPath.length) indexImg = 0;
        MM_swapImage(elementID, "", imgPath[indexImg], 1);
    }
}
function prevImage(elementID, imgPath) {
    if (imgPath.length > 0) {
        indexImg--;
        if (indexImg < 0) indexImg = imgPath.length - 1;
        MM_swapImage(elementID, "", imgPath[indexImg], 1);
    }
}

function NewWindow(mypage, myname, w, h, scroll) {
    LeftPosition = screen.width ? (screen.width - w) / 2 : 0;
    TopPosition = screen.height ? (screen.height - h) / 2 : 0;
    settings =
        "height=" +
        h +
        ",width=" +
        w +
        ",top=" +
        TopPosition +
        ",left=" +
        LeftPosition +
        ",scrollbars=" +
        scroll +
        ",resizable,";
    win = window.open(mypage, myname, settings);
}

function preg_replace(array_pattern, array_pattern_replace, my_string) {
    var new_string = String(my_string);
    for (i = 0; i < array_pattern.length; i++) {
        var reg_exp = RegExp(array_pattern[i], "gi");
        var val_to_replace = array_pattern_replace[i];
        new_string = new_string.replace(reg_exp, val_to_replace);
    }
    return new_string;
}

function verif_num_bandeau(elementID, fieldname) {
    var flg = 0;
    var num = "0123456789.,";
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + "Label");
    var erreurFieldset = document.getElementById("mes_err_fieldset");
    var erreurText = document.getElementById("erreur_msg");
    var point = 0;
    var virgule = 0;
    if (objElement.value == "") {
        erreurFieldset.style.display = "block";
        erreurText.innerHTML = ajax_file(
            "ajax_sprintf.php?arg1=js_thanks_precise&arg2=" + fieldname
        );
        objElement.className = "inputErr";
        objLabel.className = "labelErr";
        objElement.focus();
        return false;
    } else {
        for (var k = 0; k < objElement.value.length; k++) {
            var test = objElement.value.substring(k, k + 1);
            if (num.indexOf(test) < 0) flg++;
        }
        if (flg > 0) {
            erreurFieldset.style.display = "block";
            erreurText.innerHTML = ajax_file(
                "ajax_sprintf.php?arg1=js_wrong_input_num_bandeau&arg2=" +
                    fieldname
            );
            objElement.className = "inputErr";
            objLabel.className = "labelErr";
            objElement.focus();
            flg = 0;
            return false;
        }
    }
    objElement.className = "inputForm";
    objLabel.className = "labelForm";
    return true;
}

function verif_etage(elementID, fieldname, nb) {
    var flg = 0;
    var num = "0123456789rdcRDC.";
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + "Label");
    var erreurFieldset = document.getElementById("mes_err_fieldset");
    var erreurText = document.getElementById("erreur_msg");
    var point = 0;
    var virgule = 0;
    if (objElement.value == "") {
        erreurFieldset.style.display = "block";
        erreurText.innerHTML = ajax_file(
            "ajax_sprintf.php?arg1=js_thanks_precise&arg2=" + fieldname
        );
        objElement.className = "inputErr";
        objLabel.className = "labelErr";
        objElement.focus();
        return false;
    } else {
        if (objElement.value.length > nb) {
            erreurFieldset.style.display = "block";
            erreurText.innerHTML = ajax_file(
                "ajax_sprintf.php?arg1=js_at_least_n_chars&arg2=" +
                    nb +
                    "&arg3=" +
                    fieldname
            );
            objElement.className = "inputErr";
            objLabel.className = "labelErr";
            objElement.focus();
            return false;
        }
        for (var k = 0; k < objElement.value.length; k++) {
            var test = objElement.value.substring(k, k + 1);
            if (num.indexOf(test) < 0) flg++;
        }
        if (flg > 0) {
            erreurFieldset.style.display = "block";
            erreurText.innerHTML = ajax_file(
                "ajax_sprintf.php?arg1=js_wrong_input_num_rdc&arg2=" + fieldname
            );
            objElement.className = "inputErr";
            objLabel.className = "labelErr";
            objElement.focus();
            flg = 0;
            return false;
        }
    }
    objElement.className = "inputForm";
    objLabel.className = "labelForm";
    return true;
}

function verifSame(field1, field2, fieldname) {
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + "Label");
    var erreurText = document.getElementById("erreur_msg");
    $(objElement)
        .removeData("data-error-text")
        .removeAttr("data-error-text");
    if (
        document.getElementById(field1).value !=
        document.getElementById(field2).value
    ) {
        var txt_lang =
            fieldname == "login_email_verif"
                ? "js_same_email_confirm"
                : "js_same_mdp_confirm";
        t_error =
            '<p class="title_toolltip">' +
            ajax_file("ajax_sprintf.php?arg1=" + fieldname) +
            " :</p>" +
            ajax_file(
                "ajax_sprintf.php?arg1=" + txt_lang + "&arg2=" + fieldname
            );
        $(objElement).data("data-error-text", t_error);
        erreurText.innerHTML = t_error;
        $(objElement).addClass("inputErr");
        $(objLabel).addClass("labelErr");
        return false;
    }
    $(objElement).removeClass("inputErr");
    $(objLabel).removeClass("labelErr");
    return true;
}

function isClosedDay(day, month) {
    var array_closed_array = new Array(
        "1/1",
        "25/4",
        "1/5",
        "8/5",
        "2/6",
        "13/6",
        "14/7",
        "15/8",
        "1/11",
        "11/11",
        "25/12",
        "26/12"
    );
    return array_closed_array.in_array(day + "/" + month);
}

(function() {
    "use strict";

    function in_array(p_val) {
        for (var i = 0, l = this.length; i < l; i++) {
            if (this[i] == p_val) {
                return true;
            }
        }

        return false;
    }

    Object.defineProperty(Array.prototype, "in_array", {
        configurable: true,
        enumerable: false,
        writable: false,
        value: in_array,
    });
})();

function changeVisu(field, path, evt) {
    var new_img, target, current;

    field = document.getElementById(field);

    if (evt) {
        target = evt.target.parentElement;
        current = target.parentElement.getElementsByClassName("current");

        target.classList.add("current");

        Array.prototype.slice.call(current).forEach(function(elm) {
            if (elm !== target) {
                elm.classList.remove("current");
            }
        });
    }

    if (field && field.parentElement) {
        new_img = new Image();

        new_img.id = field.id;
        new_img.className = field.className;

        new_img.addEventListener("load", function() {
            this.alt = field.alt;

            this.setAttribute("width", this.naturalWidth);
            this.setAttribute("height", this.naturalHeight);

            if (field.parentElement) {
                field.parentElement.appendChild(this);
                field.parentElement.removeChild(field);
            }
        });

        new_img.src = path;
    }

    // Simpler way doesn't seem to work well with lazyloader
    // return document.getElementById(field).src = path;

    var productSwiper = new Swiper(".productSwiper", {
        slidesPerView: 1,
        observer: true,
        observeParents: true,
        navigation: {
            nextEl: '.rollover_rayonsNext',
            prevEl: '.rollover_rayonsPrev',
        },
        preloadImages: false,
        lazy: true,
        loadPrevNext: true,
        loadOnTransitionStart: true
    });
}

function initialise_productSwiper() {
    var productSwiper = new Swiper(".productSwiper", {
        slidesPerView: 1,
        observer: true,
        observeParents: true,
        navigation: {
            nextEl: '.rollover_rayonsNext',
            prevEl: '.rollover_rayonsPrev',
        },
        preloadImages: false,
        lazy: true,
        loadPrevNext: true,
        loadOnTransitionStart: true
    });
}

function getPos(obj) {
    var pos = {x: obj.offsetLeft || 0, y: obj.offsetTop || 0};
    while ((obj = obj.offsetParent)) {
        pos.x += obj.offsetLeft || 0;
        pos.y += obj.offsetTop || 0;
    }
    return pos;
}

function closeInfobulle(id) {
    if (!jQuery.browser.msie) $("#" + id).fadeOut(400);
    else document.getElementById(id).style.display = "none";
    document.getElementById(id).style.zIndex = "0";
}

var one_time = false;
function openInfobulle(id, type, elem) {
    window.clearTimeout(timer);
    $("#bloc_erreur").css("display", "none");
    if (!jQuery.browser.msie)
        $("#bloc_erreur")
            .stop(false, true)
            .fadeOut();
    if (!jQuery.browser.msie) $("#" + id).fadeIn(400);
    else document.getElementById(id).style.display = "block";
    if (typeof type != "undefined") {
        var margin_spe_left = 0;
        var margin_spe_top = 0;
        if (elem.nodeName == "SELECT") {
            id = elem.id.substr(0, elem.id.length - 1);
            if (
                id == "clientMailAuth" ||
                id == "clientPwdAuth" ||
                id == "mailLost"
            ) {
                var txt_spe = document.getElementById(id + "ErrErr").innerHTML;
                margin_spe_left = 300;
                if (id == "clientPwdAuth") margin_spe_top = 10;
                infos_alias;
            } else if (type == "banque")
                var txt_spe = document.getElementById("infos_carte").innerHTML;
            else if (type == "alias_banque")
                var txt_spe = document.getElementById("infos_alias").innerHTML;
            else var txt_spe = document.getElementById(id + "Err").innerHTML;
        } else {
            if (
                elem.id == "clientMailAuth" ||
                elem.id == "clientPwdAuth" ||
                elem.id == "mailLost"
            ) {
                if (document.getElementById(elem.id + "ErrErr"))
                    var txt_spe = document.getElementById(elem.id + "ErrErr")
                        .innerHTML;
                if (elem.id == "mailLost") margin_spe_left = -350;
                else margin_spe_left = -340;
                if (elem.id == "clientPwdAuth") margin_spe_top = 41;
                else margin_spe_top = 4;
            } else if (type == "banque")
                var txt_spe = document.getElementById("infos_carte").innerHTML;
            else if (type == "alias_banque")
                var txt_spe = document.getElementById("infos_alias").innerHTML;
            else
                var txt_spe = document.getElementById(elem.id + "Err")
                    .innerHTML;
        }
        document.getElementById("erreur_msg").innerHTML = txt_spe;
        pos = getPos(elem);
        document.getElementById("bloc_erreur").style.position = "absolute";
        if (type == "banque") {
            margin_spe_left -= 42;
            margin_spe_top += 30;
        } else if (type == "alias_banque") {
            margin_spe_left -= 42;
            margin_spe_top += 15;
        }
        document.getElementById("bloc_erreur").style.top =
            pos.y -
            (document.getElementById("bloc_erreur").offsetHeight +
                margin_spe_top -
                2) +
            "px";
        pos_left_margin = getPos(document.getElementById("content"));
        left_add = 0;
        if (document.getElementById("wrapper_anniversaire_jour1")) {
            left_add = document.getElementById("bloc_erreur").offsetWidth;
            left_add -= 35;
        }
        document.getElementById("bloc_erreur").style.left =
            pos.x +
            (elem.offsetWidth - 8) +
            margin_spe_left +
            27 -
            left_add +
            "px";
        if (type == "banque" || type == "alias_banque") {
            document.getElementById("bloc_erreur").style.width = "280px";
        } else {
            document.getElementById("bloc_erreur").style.width = "280px";
        }
        document.getElementById("bloc_erreur").style.zIndex = "10000";
    }
}

function OverPopup(elem, msg, type, is_vp) {
    clearTimeout(timer);

    if (type == "visible") {
        if (typeof is_vp != "undefined" && is_vp != "cmpid_adwords") {
            $("#bloc_erreur_vp").css("display", "none");
            if (!jQuery.browser.msie) {
                $("#bloc_erreur_vp")
                    .stop(false, true)
                    .fadeOut();
            }
            if (!jQuery.browser.msie) {
                $("#bloc_erreur_vp").fadeIn(400);
            } else {
                document.getElementById("bloc_erreur_vp").style.display =
                    "block";
            }
        } else {
            if (
                !document.getElementById("formAnniversaire") ||
                (document.getElementById("formAnniversaire") &&
                    elementID != "newsletterMailv" &&
                    elementID != "newsletterMailInscrit")
            ) {
                pos = getPos(elem);
                document.getElementById("erreur_msg").innerHTML = msg;
                if (!jQuery.browser.msie) {
                    $("#bloc_erreur")
                        .stop(true, true)
                        .fadeIn(400);
                } else {
                    document.getElementById("bloc_erreur").style.display =
                        "block";
                }
                document.getElementById("bloc_erreur").style.position =
                    "absolute";
                pos_left_margin = -13;
                var left_add = 17;
                if (document.getElementById("wrapper_anniversaire_jour1")) {
                    left_add = document.getElementById("bloc_erreur")
                        .offsetWidth;
                    left_add -= 35;
                }
                if (document.getElementById("formAnniversaire")) {
                    left_add = $("#erreur_msg").width() - 10;
                    $(".bottom_tooltip").attr("align", "right");
                }
                document.getElementById("bloc_erreur").style.left =
                    pos.x +
                    (elem.offsetWidth - 8) -
                    pos_left_margin -
                    left_add +
                    1 +
                    "px";
                if (is_vp === "cmpid_adwords") {
                    var clone = $("#bloc_erreur").clone(),
                        c_pos = {
                            top:
                                $(elem).offset().top -
                                $(elem)
                                    .offsetParent()
                                    .offset().top -
                                $("#bloc_erreur").height() +
                                4,
                            left:
                                $(elem).offset().left -
                                $(elem)
                                    .offsetParent()
                                    .offset().left -
                                $("#bloc_erreur").innerWidth() +
                                $(elem).width() * 2 +
                                6,
                        };
                    $("#bloc_erreur").remove();
                    $(elem).after(clone);
                    $("#bloc_erreur")
                        .find("td:nth-child(2)")
                        .children("img")
                        .css({
                            float: "right",
                            margin: "0 7px",
                        });
                    $("#bloc_erreur").css({
                        position: "absolute",
                        top: c_pos.top,
                        left: c_pos.left,
                        "z-index": "99999",
                        opacity: "1",
                    });
                } else {
                    document.getElementById("bloc_erreur").style.top =
                        pos.y -
                        document.getElementById("bloc_erreur").offsetHeight +
                        "px";
                }
                if (elem.id == "departement_naissance") {
                    $("#bloc_erreur").css({
                        width: 180,
                        marginTop: 63,
                        marginLeft: -310,
                    });
                }
                document.getElementById("bloc_erreur").style.zIndex = "99999";
            }
        }
    } else {
        if (typeof is_vp != "undefined" && is_vp != "cmpid_adwords") {
            if (!jQuery.browser.msie) {
                $("#bloc_erreur_vp").fadeOut(400);
            } else {
                document.getElementById("bloc_erreur_vp").style.display =
                    "none";
            }
            document.getElementById("bloc_erreur_vp").style.zIndex = "0";
        } else {
            if (!jQuery.browser.msie) {
                $("#bloc_erreur")
                    .stop(true, true)
                    .fadeOut(400);
            } else {
                document.getElementById("bloc_erreur").style.display = "none";
            }
            document.getElementById("bloc_erreur").style.zIndex = "0";
        }
    }
}

function getXY(obj) {
    var curleft = 0;
    var curtop = obj.offsetHeight + 5;
    var border;
    if (obj.offsetParent) {
        do {
            if (getStyle(obj, "position") == "relative") {
                if ((border = _pub.getStyle(obj, "border-top-width")))
                    curtop += parseInt(border);
                if ((border = _pub.getStyle(obj, "border-left-width")))
                    curleft += parseInt(border);
            }
            curleft += obj.offsetLeft;
            curtop += obj.offsetTop;
        } while ((obj = obj.offsetParent));
    } else if (obj.x) {
        curleft += obj.x;
        curtop += obj.y;
    }
    return {x: curleft, y: curtop};
}
/**
 * Returns the specified computed style on an object.
 * @param {HTMLObject} obj HTML Object
 * @param {String} styleProp Property name.
 * @return {Mixed} Computed style on object.
 */
function getStyle(obj, styleProp) {
    if (obj.currentStyle) return obj.currentStyle[styleProp];
    else if (window.getComputedStyle)
        return document.defaultView
            .getComputedStyle(obj, null)
            .getPropertyValue(styleProp);
}

/* pour les questions du panier panier */
function fadePanier(id, nbelem) {

    for (var i = 1; i <= nbelem; i++) {

        if (id != i && $("#texte_question_" + i).css('display') == 'block') {

            $("#texte_question_" + i).slideUp(400);
            $("#intitule_question_" + i).removeClass('actif');
        }
    }

    if ($("#texte_question_" + id).css('display') != 'block') {

        $("#texte_question_" + id).slideDown(400);
        $("#texte_question_" + id).addClass('actif');
        $("#intitule_question_" + id).addClass('actif');
    } else {

        $("#texte_question_" + id).slideUp(400);
        $("#texte_question_" + id).removeClass('actif');
        $("#intitule_question_" + id).removeClass('actif');
    }
}

function fadeHelp(id, nbelem) {
    for (var i = 1; i <= nbelem; i++) {
        if (id != i && $("#answer_" + i).css("display") == "block")
            $("#answer_" + i).slideUp(400);
    }
    if ($("#answer_" + id).css("display") != "block")
        $("#answer_" + id).slideDown(400);
    else $("#answer_" + id).slideUp(400);
}

/********showblocform********/
function showBlocForm(id, type) {
    clearTimeout(timer);

    if (
        $("#bloc_erreur").css("display") == "table" ||
        $("#bloc_erreur").css("display") == "block"
    ) {
        $("#bloc_erreur").css("display", "none");
    }

    if (id == "is_not_client") {
        $(".wrapper_login.left").addClass("actif");
        $(".wrapper_login.right").removeClass("actif");

        if ($("#bloc_lost_password").is(":visible")) {
            setTimeout(reloadBlocLogin, 100);
        }
    } else if (id == "is_client") {
        $(".wrapper_login.left").removeClass("actif");
        $(".wrapper_login.right").addClass("actif");
    }
}

/*********/
/********affichelostpassword********/
var afficheLostPassword = function (evt) {

    var signIn = $('#auth');
    var passWd = $('#resetPass');
    var subLogin = $('#subtitleLogin');
    var subPassword = $('#subtitlePassword');

    evt.preventDefault();

    signIn.animate({
        opacity: 0
    }, 600, function () {

        signIn.css('display', 'none');

        passWd.css('display', '').animate({
            opacity: 1
        }, 600);
    });

    subLogin.animate({
        opacity: 0
    }, 600, function () {

        subLogin.css('display', 'none');
        subPassword.css('display', 'block').animate({
            opacity: 1
        }, 600);
    });
};

/*********/
/********reloadbloclogin********/
var reloadBlocLogin = function (evt) {

    var signIn = $('#auth');
    var passWd = $('#resetPass');
    var subLogin = $('#subtitleLogin');
    var subPassword = $('#subtitlePassword');

    evt.preventDefault();

    passWd.animate({
        opacity: 0
    }, 600, function () {

        passWd.css('display', 'none');

        signIn.css('display', '').animate({
            opacity: 1
        }, 600);
    });

    subPassword.animate({
        opacity: 0
    }, 600, function () {

        subPassword.css('display', 'none');

        subLogin.css('display', '').animate({
            opacity: 1
        }, 600);
    });
};

/*********/

function afficheLostPasswordTop() {
    if ($("#bloc_erreur").is(":visible")) {
        $("#bloc_erreur").css("display", "none");
    }

    $("#is_client_form").hide();
    $("#lost_password_top")
        .css({display: "block", opacity: 0})
        .fadeIn(600);
}

function reloadBlocLoginTop() {
    if ($("#bloc_erreur").is(":visible")) {
        $("#bloc_erreur").css("display", "none");
    }

    $("#lost_password_top").hide();
    $("#returning_customer_top").fadeIn(600);
}

function toggleFullScreen() {
    var video = document.getElementById("video_spa");
    if (video.requestFullScreen) {
        video.requestFullScreen();
    } else if (video.webkitRequestFullScreen) {
        video.webkitRequestFullScreen();
    } else if (video.mozRequestFullScreen) {
        video.mozRequestFullScreen();
    }
}

function show_lightbox_3xcb() {
    $("#shad")
        .fadeTo("slow", 0.8)
        .queue(function() {
            $(this).css("display", "block");
            $("#popup_3xcb").slideDown("slow");
            $(this).bind("click", close_lightbox_3xcb);
            $(this).dequeue();
        });
}

function close_lightbox_3xcb() {
    $("#popup_3xcb").hide();
    $("#shad").hide();
    $(this).unbind("click", close_lightbox_3xcb);
}

function in_array(needle, haystack, strict) {
    // http://kevin.vanzonneveld.net
    // +   original by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
    // *     example 1: in_array('van', ['Kevin', 'van', 'Zonneveld']);
    // *     returns 1: key
    var rKey = -1,
        strict = !!strict;
    if (haystack.length > 0) {
        for (key in haystack) {
            if (
                (strict && haystack[key] === needle) ||
                (!strict && haystack[key] == needle)
            ) {
                rKey = key;
                break;
            }
        }
    }
    return rKey;
}

function in_array_js(needle, haystack) {
    var rKey = -1;
    if (haystack.length > 0) {
        for (key in haystack) {
            if (haystack[key] == needle) {
                rKey = key;
                break;
            }
        }
    }
    return rKey > -1 ? true : false;
}

$.fn.recalImg = function(img) {
    var obj = $(this);
    var img;
    if (img == "" || img == null) {
        img = $("img", obj);
    } else {
        img = $(img, obj);
    }
    img.css({
        position: "",
        height: "",
        width: "",
        left: "",
        top: "",
        marginTop: "",
        marginLeft: "",
    });
    var widthObj = obj.outerWidth(true);
    var heightObj = obj.outerHeight(true);
    var widthImg = img.width();
    var heightImg = img.width();
    obj.css({position: "relative"});
    img.css({position: "absolute"});
    if (heightImg > heightObj || widthImg > widthObj) {
        if (widthImg > heightImg) {
            img.css({height: heightObj});
        } else if (widthImg <= heightImg) {
            img.css({width: widthObj});
        }
        var widthImgNow = img.outerWidth(true);
        var heightImgNow = img.outerHeight(true);
        var difHeight = (heightObj - heightImgNow) / 2;
        var difWidth = (widthObj - widthImgNow) / 2;
        if (widthImg > heightImg) {
            img.css({left: 0, top: 0});
        } else if (widthImg <= heightImg) {
            img.css({top: 0, left: 0});
        }
    } else {
        img.css({top: "0", left: "0"});
    }
    img.show();
};

function activeChange(p, c, s) {
    if ($(p).attr("value") == "") {
        $(c).css({color: "#aaa", fontStyle: "italic"});
    } else {
        $(c).css({color: "#000", fontStyle: "normal"});
    }
    c.text($("option:selected", s).text());
    if ($("option:selected", s).val() == "") {
        $(c).css({color: "#aaa", fontStyle: "italic"});
    }
}

$.fn.cbForm = function() {
    var t = $(this);
    var fs = $(".form_select", t);
    fs.css({opacity: "1"});
    fs.hover(
        function() {
            if (!$(this).hasClass("activ") && t.hasClass("active_bloc")) {
                $(this).css({opacity: "0.5"});
            }
        },
        function() {
            if (!$(this).hasClass("activ") && t.hasClass("active_bloc")) {
                $(this).css({opacity: "1"});
            }
        }
    );
    fs.click(function() {
        if (t.hasClass("active_bloc")) {
            fs.removeClass("activ");
            fs.css({opacity: "1"});
            $(this).addClass("activ");
            $(this).css({opacity: "0.5"});
            $(".type_carte option", t).removeAttr("selected");
            var typeCarte = $(this).attr("id");
            $("#type_carte_" + typeCarte, t).attr("selected", "selected");
            p = $(".type_carte option:selected", t);
            c = $(".clone", $(".type_carte", t).parent(".selectStyled"));
            c.text(p.text());
            if ($(p).attr("value") == "") {
                $(c).css({color: "#aaa", fontStyle: "italic"});
            } else {
                $(c).css({color: "#000", fontStyle: "normal"});
            }
        }
    });
    $(".type_carte", t).change(function() {
        tcv = $("option:selected", this);
        var ctcv = tcv.val();
        fs.removeClass("activ");
        fs.css({opacity: "1"});
        if (ctcv != "") {
            $(".form_select#" + ctcv).addClass("activ");
            $(".form_select#" + ctcv).css({opacity: "1"});
        }
    });
};

function showrecuppanierlightbox() {
    var elname = "recuppanier";
    var actualposition = document.documentElement.scrollTop;
    var modbox = document.getElementById(elname);
    var topconnexion = document.getElementById("topMenuConnexionButtonInside");
    var shad = document.getElementById("shad2");
    shad.style.display = "block";
    modbox.style.display = "block";
    if (
        navigator.userAgent.indexOf("MSIE") != -1 &&
        navigator.userAgent.indexOf("7.") != -1
    ) {
        topconnexion.style.zIndex = -1;
    }
    addEventPromo(shad, "click", closerecuppanier);
    window.scrollTo(0, actualposition);
}

function closerecuppanier() {
    var modbox = document.getElementById("recuppanier");
    var shad = document.getElementById("shad2");
    modbox.style.marginLeft = "";
    shad.style.display = "none";
    modbox.style.display = "none";
    document.body.style.overflow = "";
    document.getElementsByTagName("html")[0].style.overflow = "";
    delEventPromo(shad, "click", closerecuppanier);
}

function addEventPromo(event, name_function) {
    if (document.all)
        document
            .getElementById("shad")
            .attachEvent("on" + event, name_function);
    else
        document
            .getElementById("shad")
            .addEventListener(event, name_function, false);
}
function delEventPromo(event, name_function) {
    if (document.all)
        document
            .getElementById("shad")
            .detachEvent("on" + event, name_function);
    else
        document
            .getElementById("shad")
            .removeEventListener(event, name_function, false);
}

function addFileInput(id_input, string) {
    var field = typeof string != "undefined" ? string : "contactProduitPhoto";
    var id = parseInt(id_input);
    var next_file = id + 1;
    var file = $("#" + field + id_input)[0].files[0];
    var error = false;
    var input =
        field == "contactProduitPhoto"
            ? $("#photoProduit_display")
            : $("#photoProduitEndommage_display");
    var nb_input =
        field == "contactProduitPhoto" ? $(input).val() : $(input).val();
    nb_input = parseInt(nb_input);
    size = file.size;
    type = file.type;
    var allowedType = new Array("image/jpeg", "image/png", "application/pdf"); // Type de fichiers autoris�s
    if (size > 4000000) {
        alert("Poids Max : 4Mo");
        error = true;
    } else if (jQuery.inArray(type, allowedType) == -1) {
        alert("Format autoris&eacute; : jpg, png, pdf");
        error = true;
    }
    if (error) {
        if (id_input == 1) {
            $("#field_send").addClass("cache");
            $("#" + field + next_file).remove();
        }
        $("#" + field + id_input).val("");
    } else {
        if (id_input == 1) {
            $("#field_send").removeClass("cache");
        }
        if (field != "filepj") {
            var params =
                typeof string != "undefined" ? ',"' + string + '"' : "";
            var style =
                typeof string != "undefined" &&
                string != "contactProduitPhotoEndommage"
                    ? 'style="float: left; padding-left: 32px;padding-top:-2px;"'
                    : "";
            $("#" + field + id_input).after(
                "<input " +
                    style +
                    " class='product_file' id='" +
                    field +
                    next_file +
                    "' value='' type='file' name='" +
                    field +
                    next_file +
                    "' onchange='addFileInput(" +
                    next_file +
                    params +
                    ")' /> "
            );
            $(input).attr("value", next_file);
        }
    }
}

function uploadfile() {
    //fonction pour valider le fichier en pj dans le formulaire message
    var error = false;
    var fname = $("#message_file").val();
    var fsize = $("#message_file")[0].files[0].size; //get file size
    var ftype = $("#message_file")[0].files[0].type; // get file type
    var allowedType = new Array("image/jpeg", "image/png", "application/pdf"); // Type de fichiers autoris�s
    if (fsize > 4000000) {
        alert("Poids Max : 4Mo");
        error = true;
    } else if (jQuery.inArray(ftype, allowedType) == -1) {
        alert("Format autorise : jpg, png, pdf");
        error = true;
    }
    if (error) {
        $("#message_file").val("");
    } else {
    }
}

function show_popup(elem) {
    openMultiShad(elem);
}

function close_popup(elem) {
    var $modbox = $("#" + elem);
    var $shad = $("#shad");
    $shad.attr("onclick", "");
    $shad.animate({opacity: 0}, 350, function() {
        $shad.css({display: "none", opacity: ""}).data("opened_lb", 0);
    });
    $modbox.css({display: "none"});
}

function openMultiShad(id) {

    // Show modbox and make sure closing routine won't fire more than once
    $("#" + id).fadeIn(200, function () {

        $("#" + id).slideDown(600);
    });

    if (id !== 'zoomBox') {
        $("#shad").css("display", "block").fadeTo("slow", 0.8).on("click", function () {
            closeMultiShad(id);
        });
    }

    if(id === "zoomBox") {
        $('body').css('position','fixed');
        $('#zoom_box_wrapper .img_large').on('click', function () {
            closeMultiShad('zoomBox');
        });
    }

    if ($("#buyNowSwiper").length > 0) {

        window.setTimeout(function () {
            buyNowSwiper = new Swiper("#buyNowSwiper", {
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
            });
        }, 50);
    }

    if(id === "sizeguideBox"){
        window.setTimeout(function () {
            $("#wrap_itable").lionbars();
        }, 300);

        $('body').css('position','fixed');
    }

    if (id == 'selection-offer') {
        /* SWIPER SELECTION-OFFER-CONTENT */
        /* CHOIX COULEUR */
        //On check individuellement chacun des sliders avec each() afin de ne pas fausser le comptage des swiper slide
        $.each($('#selection-offer-content .color_list.swiper-container'), function() {
            if ($(this).find('.swiper-slide').length > 4) {
                new Swiper($(this).get(), {
                    slidesPerView: 5,
                    navigation: {
                        nextEl: '.nextColour',
                    }
                });
            }
        });

        /* CHOIX SIZE */
        //On check individuellement chacun des sliders avec each() afin de ne pas fausser le comptage des swiper slide
        $.each($('#selection-offer-content .size_list.swiper-container'), function() {
            if ($(this).find('.swiper-slide').length > 3) {
                new Swiper($(this).get(), {
                    slidesPerView: 4.5,
                    // centeredSlides: true,
                    width: 179,
                    navigation: {
                        nextEl: '.nextSize',
                    }
                });
            }
        });

        if($('#selection-offer-content #wrap_lion .js-form-selection-offer').length > 2) {
            $('#selection-offer-content').overlayScrollbars({});
        }
    }
}


function closeMultiShad(id) {
    $("#shad").off("click");
    $('body').css('position','');

    // Hide modbox and shade and destroy shade's click event
    $("#" + id)
        .slideUp("slow")
        .queue(function() {
            if ($("#shad").is(":visible")) {
                $("#shad").fadeTo(100, 0, function() {
                    $("#shad").css("display", "none");
                });
            }

            $(this).dequeue();
    });
}

function deplieMsg(id) {
    $(".btn-deplier").removeClass("deplier-opened");
    $(".detail_message").slideUp();
    $("#conversation_msg_" + id)
        .slideDown()
        .parent()
        .find(".btn-deplier")
        .addClass("deplier-opened");
}

function showDetailOrder(order_id, order_type) {
    if (show_orders_by_type("orders_by_type", order_type, ROOT_PATH))
        $("#order_" + order_id).click();
    $("html, body").animate(
        {
            scrollTop: $('[data-btn-name="order_section"]').offset().top - 100,
        },
        500
    );
}

function changeLocale(locale, uri) {
    $.ajax({
        url: path_relative_root + "ajax_get_uri_by_lang.php",
        type: "post",
        data: {lang: locale, url: uri},
        success: function(data) {
            if (data != "") {
                var force = "";
                if (uri == "/") {
                    force = "?force";
                }

                location.href = data + force;
            }
        },
    });
}

function actionKeyCacheCache() {
    $(".key_cache_cache").bind("click", function() {
        var $objId = $(this).attr("id");
        openPopupCacheCache($objId);
    });
}

$.fn.new_styling_form = function(option) {
    var $obj = $(this);
    var param = $.extend(
        {
            target_type: "multi",
            type_class_sufix: "styled",
            localName_new_elem: "div",
            new_elem_id: true,
            height: "auto",
            use_class: "false",
            select_noValue_color: "#aaa",
        },
        option
    );

    function animeElem(action, styled, height) {
        var useClass = param.use_class == true;
        styled.removeClass("unselected selected btndown");
        if (action == "inActive") {
            styled.css({backgroundPosition: "0 0"}).addClass("unselected");
        }
        if (action == "active") {
            styled
                .css({backgroundPosition: "0 -" + height * 2 + "px"})
                .addClass("selected");
        }
        if (action == "btnDownToActive" || action == "btnDownToInActive") {
            styled.addClass("btndown");
            if (action == "btnDownToActive") {
                styled.css({backgroundPosition: "0 -" + height * 1 + "px"});
            }
            if (action == "btnDownToInActive") {
                styled.css({backgroundPosition: "0 -" + height * 3 + "px"});
            }
        }
    }

    $obj.each(function() {
        var $objEach = $(this);
        var eachLocalName = $objEach[0].tagName.toLowerCase();
        var localNameValid = new Array("input", "select");
        var flag = true;
        var eachType = $objEach[0].type;
        var isInputForm = $.inArray(eachLocalName, localNameValid) >= 0;

        var acceptedType = new Array("radio", "checkbox");
        var isAccepted = $.inArray(eachType, acceptedType) >= 0;
        var idElem = $objEach.prop("id");

        if (!isInputForm || (isInputForm == "input" && !isAccepted)) {
            flag = false;
        }

        var elemType = param.target_type;
        if ((elemType = "multi")) {
            elemType = eachType;
        } else if (elemType != eachType) {
            flag = false;
        }
        if ($objEach.data("isStyled")) {
            flag = false;
        }

        if (flag) {
            var prefix = eachLocalName == "input" ? eachType : eachLocalName;
            var styledClass = prefix + "_" + param.type_class_sufix;
            if (param.type_class_sufix == "") {
                styledClass = prefix;
            }

            if (
                !$objEach
                    .prev()
                    .is(param.localName_new_elem + "." + styledClass)
            ) {
                var $styledElem = $(
                    document.createElement(param.localName_new_elem)
                );
                $objEach.before($styledElem);
                $styledElem.addClass(styledClass).css({cursor: "pointer"});
                if (eachLocalName == "input") {
                    $objEach.css({display: "none"});
                }
                if (eachLocalName == "select") {
                    $objEach.css({position: "relative", opacity: 0, zIndex: 5});
                }
            } else {
                var $styledElem = $(
                    param.localName_new_elem + "." + styledClass
                );
            }

            if (param.new_elem_id) {
                $styledElem.prop("id", eachLocalName + $objEach.prop("id"));
            }

            var objHeight = param.height;
            if (objHeight == "auto") {
                objHeight = $styledElem.height();
            }
            $objEach.data("isStyled", true);
            var objChecked = $objEach.is(":checked");

            if (eachLocalName == "input") {
                if (objChecked) {
                    animeElem("active", $styledElem, objHeight);
                } else {
                    animeElem("inActive", $styledElem, objHeight);
                }
            }

            if (eachLocalName == "input") {
                if (eachType == "checkbox") {
                    $styledElem.on({
                        mousedown: function(e) {
                            var objChecked = $objEach.is(":checked");
                            if (e.which == 1) {
                                if (objChecked) {
                                    animeElem(
                                        "btnDownToInActive",
                                        $styledElem,
                                        objHeight
                                    );
                                } else {
                                    animeElem(
                                        "btnDownToActive",
                                        $styledElem,
                                        objHeight
                                    );
                                }
                            }
                        },
                        mouseup: function(e) {
                            var objChecked = $objEach.is(":checked");
                            if (e.which == 1) {
                                if (objChecked) {
                                    $objEach.prop("checked", null);
                                } else {
                                    $objEach.prop("checked", "checked");
                                }
                                $objEach.trigger("change");
                            }
                        },
                    });
                    $objEach.on({
                        change: function() {
                            var objChecked = $objEach.is(":checked");

                            if (objChecked) {
                                animeElem("active", $styledElem, objHeight);
                            } else {
                                animeElem("inActive", $styledElem, objHeight);
                            }
                        },
                    });
                }
                if (eachType == "radio") {
                    var eachName = $objEach[0].name;
                    var $otherRadio = $obj
                        .not($objEach)
                        .filter("[type=radio][name=" + eachName + "]");

                    $styledElem.on({
                        mousedown: function(e) {
                            var objChecked = $objEach.is(":checked");
                            if (e.which == 1) {
                                if (!objChecked) {
                                    animeElem(
                                        "btnDownToActive",
                                        $styledElem,
                                        objHeight
                                    );
                                }
                            }
                        },
                        mouseup: function(e) {
                            var objChecked = $objEach.is(":checked");
                            if (e.which == 1) {
                                if (!objChecked) {
                                    $otherRadio.prop("checked", null);
                                    $objEach.prop("checked", "checked");
                                    animeElem("active", $styledElem, objHeight);
                                    $otherRadio.each(function() {
                                        var otherEach = $(this);
                                        animeElem(
                                            "inActive",
                                            otherEach.prev("." + styledClass),
                                            objHeight
                                        );
                                    });
                                }
                                $objEach.trigger("change");
                            }
                        },
                    });
                    $objEach.on({
                        change: function() {
                            var objChecked = $objEach.is(":checked");
                            if (objChecked) {
                                animeElem("active", $styledElem, objHeight);
                                $otherRadio.each(function() {
                                    var otherEach = $(this);
                                    animeElem(
                                        "inActive",
                                        otherEach.prev("." + styledClass),
                                        objHeight
                                    );
                                });
                            }
                        },
                    });
                }
            }
            if (eachLocalName == "select") {
                if ($styledElem.text() == "") {
                    $styledElem.text($("option:selected", $objEach).text());
                }
                if (
                    $("option:selected", $objEach).val() == "" ||
                    $("option:selected", $objEach).val() == undefined
                ) {
                    $styledElem.css({
                        color: param.select_noValue_color,
                        fontStyle: "italic",
                    });
                } else {
                    $styledElem.css({color: "", fontStyle: ""});
                }
                $objEach.on({
                    change: function() {
                        if (
                            $("option:selected", $objEach).val() == "" ||
                            $("option:selected", $objEach).val() == undefined
                        ) {
                            $styledElem.css({
                                color: param.select_noValue_color,
                                fontStyle: "italic",
                            });
                        } else {
                            $styledElem.css({color: "", fontStyle: ""});
                        }
                        $styledElem.text($("option:selected", $objEach).text());
                    },
                });
            }
        }
    });
};

$.fn.selectChangeAdr = function() {
    var $obj = $(this);
    var obj_val = $obj.val();
    var id_transp = $obj.attr("id");
    var data = {};
    var $changeContainer = $(
        ".wrapper_form_adresse",
        $obj.closest(".adresse_change")
    );
    data.act = "ajout";
    data.id_transp = id_transp;
    data.no_encode_ajax = true;
    data.modif_type = "ajax";

    if (obj_val != "") {
        if (obj_val != 0) {
            data.idAdresse = obj_val;
            data.act = "modif";
        }
        if ($changeContainer.is(".show")) {
            $changeContainer
                .removeClass("show")
                .slideUp()
                .html("");
        }
        $.ajax({
            type: "get",
            data: data,
            url: path_relative_root + "livraison-ajax.php",
            success: function(response) {
                var $responce = $(response);
                var min_form = $(".min_form", $responce).html();
                var $form = $("#adresseForm", $responce);
                var all_form;

                $changeContainer
                    .html($form.html(min_form))
                    .css({display: "none"})
                    .slideDown();
                $(".styled").new_styling_form({
                    type_class_sufix: "",
                    localName_new_elem: "span",
                });
                if (!$changeContainer.is(".show")) {
                    $changeContainer.addClass("show").slideDown();
                }
                try {
                    new DeliveryPage().init();
                } catch (e) {
                    console.log(e);
                }
                initialize_google_place("delivery");
                $(".type_adresse", ".choix_type_adresse.delivery").on(
                    "click",
                    function() {
                        $(this).show_adresse_title(
                            $(".type_adresse", ".choix_type_adresse.delivery"),
                            "#adresseTitre"
                        );
                    }
                );

                var country_select = "";

                if (typeof $("#pays").val() != "undefined") {
                    country_select = $("#pays")
                        .find(":selected")
                        .data("role")
                        .toLowerCase();

                    $("#pays").change(function() {
                        if (
                            typeof $(this)
                                .find(":selected")
                                .data("role") != "undefined"
                        ) {
                            country_select = $(this)
                                .find(":selected")
                                .data("role")
                                .toLowerCase();
                        }

                        if (typeof $("#telephone").val() != "undefined") {
                            $("#telephone").intlTelInput(
                                "setCountry",
                                country_select
                            );
                        }

                        if (typeof $("#mobile").val() != "undefined") {
                            $("#mobile").intlTelInput(
                                "setCountry",
                                country_select
                            );
                        }
                    });
                } else {
                    country_select = "fr";
                }
            },
        });
    }
};

var inAnime = false;
$.fn.advanceSlide = function(to, callback) {
    var $obj = $(this);
    var isVisible = $obj.is(":visible");
    var callbackfn = function() {
        if (callback && typeof callback === "function") {
            return callback();
        }
        return false;
    };
    if ($obj.data("height") == undefined || $obj.data("height") == 0) {
        if (!isVisible) {
            $obj.css({display: "block", opacity: 0, position: "absolute"});
        }
        $obj.data("height", $obj.height());
        if (!isVisible) {
            $obj.css({display: "none", opacity: "", position: ""});
        }
    }

    var objH = $obj.data("height");
    if (inAnime == false) {
        inAnime = true;
        if (to == "show" && !isVisible) {
            $obj.stop()
                .css({display: "block", overflow: "hidden", height: 0})
                .animate({height: objH}, function() {
                    $obj.css({overflow: "", height: ""});
                    inAnime = false;
                    callbackfn();
                });
        } else if (to == "hide" && isVisible) {
            $obj.stop()
                .css({display: "block", overflow: "hidden", height: objH})
                .animate({height: 0}, function() {
                    $obj.css({display: "none", overflow: "", height: ""});
                    inAnime = false;
                    callbackfn();
                });
        } else {
            inAnime = false;
        }
    }
};

function billDisplay(that) {
    var $obj = $("#wrapper_bill");
    var $this = $(that);

    if (!$this.is(":checked")) {
        $obj.stop().slideDown();
    } else {
        $obj.stop().slideUp();
    }
}

(function() {
    "use strict";

    var stickyCnt = 0;

    function initStickies() {
        var stickies = document.getElementsByClassName("sticky");

        Array.prototype.slice.call(stickies).forEach(function(sticky) {
            if (sticky.id !== "site_head_wrap") {
                if (sticky.id === "") {
                    stickyCnt += 1;
                    sticky.id = "sticky-" + stickyCnt;
                }

                sticky = new ScrollLock(sticky.id);
            }
        });
    }

    $(initStickies);
})();

$(function() {
    $(document).on("click", ".button", btnLoaderTrigger);
});

$(function() {
    'use strict';
    $("#cart_top").on('mouseenter touchstart', function (evt) {
        var count_bask = $('#cart_top .cart').attr('data-cart');

        if(count_bask > 2){
            $("#cart_list").lionbars();
        }
    });
});

/**
Enables hover state on any element when using a touch based device
 */

$(function() {
    $("#com_onglet").on("click", ".onglet", function(evt) {
        if ($("a", this).length && !$(this).hasClass("actif")) {
            $(this)
                .addClass("actif")
                .siblings()
                .removeClass("actif");
        }
    });
});

/**
 * Peremt de récupérer les stocks des tailles par couleur ainsi que leur stock
 * @author Timothy Beard / Aurélien Renault
 * @since  2017-09-27
 */
function setAvailableSizes() {
    if (window.json_colisages !== undefined) {
        var sizes_tab = window.json_colisages[$(this).val()];
        var size_box, is_disabled;

        for (size_id in sizes_tab) {
            if (sizes_tab.hasOwnProperty(size_id)) {
                $("#btn_resa_shop").show();
                $("#bloc_add_alert").hide();
                size_box = $('.product_main_wrapper [name="itm_size"]').filter(
                    '[value="' + size_id + '"]'
                );
                is_disabled = !sizes_tab[size_id];
                if( is_disabled )  {
                    size_box.addClass('disabled');
                }
                else {
                    size_box.removeClass('disabled');
                }
            } else {
                $("#btn_resa_shop").hide();
                $("#bloc_add_alert").show();
            }
        }
    }
}

/**select change function **/
$("body").on("change", "select", function() {
    $(this).addClass("changed");
});

$("select").change(function() {
    $(this).addClass("changed");
});

// Show hide div Livraison Gratuit
function cookieHeaderDelivery() {
    var d = new Date();
    d.setTime(d.getTime() + (36000*2400)); // cookie expires in 24h (= 86400000 ms)
    var expires = "expires="+d.toUTCString();

    document.cookie = "cookie_header_delivery=accepted;" + expires;
}

function getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for(var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
    }

$(function() {
    var headerDelivery = $(".header_mention");

    headerDelivery.remove();

    if (getCookie("cookie_header_delivery") != "") {
        $("#site_head_wrap").removeClass("bandeau_on");
        return;
    } else {
        $("#site_head_wrap").prepend(headerDelivery);
        headerDelivery.slideDown(500);
        headerDelivery.addClass("display");
        $("body").addClass("bandeauDelivery");

        $(".close_delais").on("click", function() {
            cookieHeaderDelivery();
            headerDelivery.slideUp(500, function() {
                headerDelivery.remove();
            });
            $("body").removeClass("bandeauDelivery");
            $("#site_head_wrap").removeClass("bandeau_on");
        });
    }
});

$(".bloc_lightbox_preco").on("click", function() {
    if (!$(this).hasClass("actif")) {
        $(".bloc_lightbox_preco.actif").removeClass("actif");
        $(this).addClass("actif");
    }
});

$.fn.initAssocBloc = function(options) {
    var options = $.extend(
        {
            numberItem: 3,
        },
        options
    );
    var element = $(this);

    function uniq(a) {
        return a.sort().filter(function(item, pos, ary) {
            return !pos || item != ary[pos - 1];
        });
    }

    function getRandomInt(min, max) {
        return Math.floor(Math.random() * (max - min + 1) + min);
    }

    function reSortInnerElem(elem, sortArr) {
        var i = 0;
        var returnList = [];
        $("div.alt_sld_view", elem).each(function(index) {
            if (i >= sortArr.length) {
                i = 0;
            }

            var tempList = [];
            var j = 0;
            $("div.alt_sld_view", elem).each(function() {
                if ($(this).attr("data-emplacement") == sortArr[i]) {
                    tempList[j] = $(this).prop("outerHTML");
                    j++;
                }
            });

            var randomNum = getRandomInt(0, tempList.length - 1);
            returnList[index] = tempList[randomNum];
            i++;
        });
        return returnList;
    }

    function displayItem(eleme) {
        var element = eleme;
        var sortList = [];
        var list = [];
        var innerList = "";

        $("div.alt_sld_view", element).each(function(i, li) {
            sortList[i] = $(this).attr("data-emplacement");
        });
        sortList = uniq(sortList);
        list = reSortInnerElem(element, sortList);
        for (var i = 0; i < options["numberItem"]; i++) {
            innerList += list[i];
        }
        element.html(innerList);

        return true;
    }

    return displayItem(element);
};

/* Add class to body on page mes_infos.php*/
var sPath = window.location.pathname;
var sPage = sPath.substring(sPath.lastIndexOf('/') + 1);
if(sPage === "mes_infos.php"){
    $('body').addClass("mes_infos");
}


function fix_elements(){
    fix_filter('#choix_recherche', '#site_head_wrap.sticky', true, 120);
}

function fix_filter(s_element, s_offset, recalc, h){
    if($(s_element).length) {
        if($('#header_mention_id').length) {
            var h = h + 40;
        }

        if(recalc){
            $(s_element).stick_in_parent({inner_scrolling:false, offset_top:h, recalc_every:1});
        }
        else{
            $(s_element).stick_in_parent({inner_scrolling:false, offset_top:h});
        }
    }
}

$(function () {
    window.addEventListener("scroll", fix_elements);
    fix_elements();
});

function goBack(toUrl) {
    if (toUrl) {
        window.location.href = path_relative_root;
    } else {
        var url = window.location.href;
        if (url.indexOf("#search") > -1) {
            var new_url = url.substring(0, url.indexOf("#search"));
            window.location.href = new_url;
        } else {
            window.history.back();
        }
    }
}

/* PLACER TABLEAU FRAIS PORT DANS SA SECTION RESPECTIVE */
$('.frais_port').appendTo('.order_cgv #bloc_4');


/* SWIPER LOOKBOOK HOMEPAGE */
$(function () {
    if ($('.image-image #super_home_swiper.swiper-container').length) {
        new Swiper('.image-image #super_home_swiper.swiper-container', {
            loop: true,
            loopAdditionalSlides: 1,
            slidesPerView: 1,
            navigation: {
                 nextEl: '.swiper-button-next.super_home_swiper_nxt',
                 prevEl: '.swiper-button-prev.super_home_swiper_prv',
             }
        });
    }
});

/* PAGE CONNEXION */
$(function () {
    if($('.login_bloc .form-msg').hasClass('active')){
        $('.login_page .wrapper_login.right').addClass('actif');
        $('.login_page .wrapper_login.left').removeClass('actif');
    }
});

/* HOMEPAGE SIZE */
$('.productSizeFieldset .size label').on("click", function() {
    $('.productSizeFieldset .size label.selected').removeClass('selected');
    $(this).addClass('selected');
});

$(function () {
    if($('#exit_overlay_OVI.lightbox').css("display") == "block"){
        $("#shad_abox").show();
    }

    $('#exit_overlay_OVI .close').on("click", function() {
        $("#shad_abox").hide();
        $("#exit_overlay_OVI").hide();
    });
});

$('.title_ctn .big_title_charte span').each(function(){
    $(this).click(function(){
        $('.sat_article').removeClass('active');
        $('.big_title_charte').removeClass('active');
        $(this).parent().addClass('active');
        if($(this).parent().hasClass('article1')){
            $('.sat_article').removeClass('active');
            $('#article1').addClass('active');
        }
        if($(this).parent().hasClass('article2')){
            $('.sat_article').removeClass('active');
            $('#article2').addClass('active');
        }
        if($(this).parent().hasClass('article3')){
            $('.sat_article').removeClass('active');
            $('#article3').addClass('active');
        }
    });
});

$(function() {

    var reviewsSlider = new Swiper('#bloc_reviews_slider', {
        slidesPerView: 1,
        loop: true,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    });

    $('#bloc_reviews_slider .swiper-slide').each(function(){
        var checkLength = $(this).find('.text_ctn p').text().length;
        if(checkLength > 400){
            $(this).find('.wrap_rating').addClass('active');
        }
    });

    $('#distribute_section_a .time_table td + td').each(function(){
        var fermer = $(this).text().trim();
        if(fermer === 'fermé'){
            $(this).css('text-transform','uppercase');
        }
    });

});

//Utilisé pour le scroll
function fix_rayon_display() {
    if ($("#list_item .item").length > 0) {
        var items = $("#list_item .item");
        var itemCount = $("#list_item .item").length;

        if (itemCount % 3 == 2){
            $("<div class=\"item empty\"></div>").insertAfter(items.last());
        }
    }
}

$('body').on('click','.search_recap',function(){
    $('#trigger_recherche').click();
    $('#search_home').focus();
});

/* productswiper rayon */
if($(".productSwiper").length) {

    $('body').on('mouseenter touchstart scroll resize','.productSwiper:not(.swiper-container-initialized)',function() {
        var productSwiper = new Swiper($(this), {
            roundLengths: true,
            setWrapperSize: true,
            slidesPerView: 1,
            observer: true,
            navigation: {
                nextEl: '.rollover_rayonsNext',
                prevEl: '.rollover_rayonsPrev',
            },
            preloadImages: false,
            lazy: true,
            loadPrevNext: true,
        });

        setTimeout(function() {
            $('.productSwiper.swiper-container-initialized').css('background-image', 'none');
        }, 150);
    });

};

$(document).ready(function() {
    initFPCharacts();

});

function initFPCharacts() {
    if ($(".fp_characts").length) {
        $(".fp_characts").on("mouseenter", function() {
            $('.fp_characts').removeClass("active");
            $('.fp_characts_description').hide();
            var id = $(this).attr('caract_id');
            $(this).addClass("active");
            $(".characts_description_" + id).slideDown('slow');
        });
        $(".fp_characts").on("mouseleave", function() {
            $('.fp_characts').removeClass("active");
            $('.fp_characts_description').hide();
        });
    }

     if ($(".fp_characts").length > 3) {
        $('#list_fp_characts').css('justify-content','space-between');
        $('#list_fp_characts .fp_characts').css('margin-right','0');
    }
}

$(function(){
    if($('#bloc_reviews_slider .swiper-slide').length){
        $('#bloc_reviews_slider .swiper-slide').each(function(){
            $(this).find('.text_ctn').overlayScrollbars({});
        });
    }
});

/**
 * Gestion chat box
 */
function startBotSDKEventsListeners () {

    var chatBoxInsert = document.getElementById('chatbot-insert');

    window.botSDK.onInitEnd(function (e) {

        chatBoxInsert.style.width = '100px';
        chatBoxInsert.classList.add('chatinitialized');
    });

    window.botSDK.onSDKLoaded(function (e) {

        chatBoxInsert.style.width = '100px';
        chatBoxInsert.classList.add('chatLoaded');
    });

    window.botSDK.onOpenBot(function (e) {

        chatBoxInsert.style.width = '410px';
        chatBoxInsert.classList.add('chatOpened');
        chatBoxInsert.classList.remove('chatClosed');
        chatBoxInsert.classList.remove('chatinitialized');
    });

    window.botSDK.onCloseBot(function (e) {

        chatBoxInsert.style.width = '100px';
        chatBoxInsert.classList.add('chatClosed');
        chatBoxInsert.classList.remove('chatOpened');
        chatBoxInsert.classList.remove('chatinitialized');
    });
}

window.addEventListener('sdk-loaded', startBotSDKEventsListeners);


/* FOOTER */

if (~window.location.href.indexOf("#retour_gratuits")) {
    $('#theme_page').addClass('actif');
    $('.theme-id-46').addClass('active');
    $('.faq_theme_46').addClass('active');
    $('.faq_question_296').addClass('active');
    $('.faq_questions_46').show().removeClass('cache');
    $('.faq_reponse_296').show().removeClass('cache');
    $('#theme_global').removeClass('actif');

    $("html, body").delay(1000).animate({
        scrollTop: $('.faq_element.active').offset().top
    }, 1500);
}

/* RAYON */
window.setTimeout(function() {
    $('.productColorFieldset .choices_list .form_itm [type=radio]').each(function(){
        if ($(this).is(':checked')) {
            if($(this).parent().hasClass('form_itm')){
                $(this).parent().addClass('active');
            }
        }
    });

    if ( $( ".item-push.push" ).length ) {
        $( "#list_item" ).addClass("has_push");
    } else {
        $( "#list_item" ).removeClass("has_push");
        $( "#list_item" ).addClass("no_push");
    }

}, 750);

/* apparition en fondu pour la pahe lookbook */
$(document).ready(function() {
    if ($('.rayon_lookbook #list_item').length) {
        setTimeout(function () {
            $('.rayon_lookbook #list_item').fadeTo("fast", 1 , function() {
                //callback
            });
        }, 200);
    }
});

/**@ Page mes_infos.php password field add attribute */
$(document).ready(function() {
    $(".mes_infos form[name=login] :input[name=clientPwd]").attr('autofill', 'false');
    $(".mes_infos form[name=login] :input[name=clientPwd]").attr('autocomplete', 'false');
    $(".mes_infos form[name=login] :input[name=clientPwd]").val('');

    $( ".wishlist .lightbox #content_sendwishlistbox .popup_title .title" ).append( Translator.translate('ma_wishlist') );
});
/**@ Page mes_infos.php clear password timeout */
window.setTimeout(function() {
    $(".mes_infos form[name=login] :input[name=clientPwd]").val('');
}, 500);

/* Page Boutique */
window.setTimeout(function() {
    if ( $( ".boutique_wrapper" ).length ) {
        $( "body" ).addClass("boutique");
    }
}, 500);

/* Ajouter le texte voir le detail des produits dans le recap commande */
window.setTimeout(function() {
    var bill_line_toogle_produit_livraison = $('body.step_2_0.in_tunnel #cart_total');
    var bill_line_toogle_produit_livraison_choix = $('body.step_2.in_tunnel #cart_total');
    var bill_line_toogle_produit_updated_line = $('<p class="voir_detail">' + Translator.translate('voir_detail_produit') + '</p>');
    $(bill_line_toogle_produit_updated_line).appendTo(bill_line_toogle_produit_livraison);
    $(bill_line_toogle_produit_updated_line).appendTo(bill_line_toogle_produit_livraison_choix);
}, 500);

/* Ajouter texte connexion sur la page identification tunnel */
window.setTimeout(function() {
    $('<h2>' + Translator.translate('connexion') + '</h2>').prependTo('.body_login.in_tunnel .newlogin_page .login_form_wrapper .title');
}, 500);

/* Changer le positionnement du bloc product_attributs */
$(function() {
    if ($('body.step_1 .pushBasketWrapper').length) {
        $('body.step_1 .pushBasketWrapper').insertBefore('body.step_1 .bloc_question').show();
    }
    if ($('body.step_1 .pushBasketWrapperText').length) {
        $('body.step_1 .pushBasketWrapperText').insertBefore('body.step_1 .cart_main_title.basket').show();
    }
});

/* Ajouter le toggle sur le recap commande pour le total_produit_dropdown */
window.setTimeout(function() {
    var bill_line_total_produit = $('body.cart #cart_total .total_produit');
    var voir_detail = $('#cart_total .voir_detail');

    $(bill_line_total_produit).unbind( "click" );

    $(voir_detail).click(function(){
        $(bill_line_total_produit).toggleClass("open");
        $(".total_produit_dropdown").slideToggle();
    });

    $(voir_detail).toggle(function() {
        $(this).text(Translator.translate('fermer_detail_produit'));
    }, function() {
        $(this).text(Translator.translate('voir_detail_produit'));
    });
}, 500);

/* Activer le bloc wishlist sur le panier */
$(function() {
    if($(".cart_main_table.wishlist").length) {
        var wishlist_title = $('body.step_1 #main_cart_wrapper .cart_main_title.wishlist');
        var wishlist_bloc = $('body.step_1 #main_cart_wrapper .cart_main_table.wishlist');

        $(wishlist_title).click(function(){
            $(this).toggleClass('active');
            $(wishlist_bloc).toggleClass('active');
        })
    }
});

/* Activer le cart_slider_wrap sur le panier */
$(function() {
    if($(".cart_main_table").length) {
        var cart_slider_wrapper_title = $('body.step_1 #cart_slider_wrap .full_w_sldr_title');
        var cart_slider_wrapper_bloc = $('body.step_1 #main_cart_wrapper #cart_slider');
        var cart_slider_wrapper_all = $('body.step_1 #cart_slider_wrap');
        var cart_slider_pagination = $('body.step_1 .cart_slider_wrapper_container .swiper-pagination');
        var cart_slider_button = $('body.step_1 .cart_slider_wrapper_container .swiper-button');

        $(cart_slider_wrapper_title).click(function(){
            $(this).toggleClass('active');
            $(cart_slider_wrapper_bloc).toggleClass('active');
            $(cart_slider_wrapper_all).toggleClass('open');
            $(cart_slider_pagination).toggleClass('active');
            $(cart_slider_button).toggleClass('active');
        })
    }
});

/*Déplacer le prix produit dans la wishlist auprès de titre*/
window.setTimeout(function() {
    var product_mod = $('body.cart .cart_main_table.wishlist .product_mod .cart_product_title');
    var wrapper_cart_product_price = $('body.cart .cart_main_table.wishlist .wrapper_cart_product_price');

    $(wrapper_cart_product_price).appendTo(product_mod);
}, 100);

/* Changer le texte du bouton sur le FP quand taille pas selectionné */
function eventButton() {

    $('.prodColor').on("click", function() {

        "use strict";

        checkSubmitState();
    });

    $('.prodSize').on("click", function() {

        "use strict";

        checkSubmitState();
    });

    var addCartWrapper = $(".btnAddBasketWrapper");
    var btn_add_cart = $("#btn_add_cart");
    var addToWishlistButton = $(".addWishlistBtn");
    var disabledBtnTooltip = $(".disabledBtnTooltip");

    addCartWrapper.on({
        mouseover: function() {
            if($(this).hasClass("inactiv")) {
                btn_add_cart.val(Translator.translate('choisir_taille'));
            }
        },
        mouseout: function() {
            // If product is preorder, display preorder, else display add to basket
            if ($("#preorderEclat").length) {
                btn_add_cart.val(Translator.translate('add_basket_preco'));
            } else {
                btn_add_cart.val(Translator.translate('add_basket'));
            }
        }
    });

    addToWishlistButton.on({
        mouseover: function() {
            if ($(addCartWrapper).hasClass("inactiv")) {
                btn_add_cart.val(Translator.translate('choisir_taille'));
                if (disabledBtnTooltip) {
                    disabledBtnTooltip.addClass('actif');
                }
            }
        },
        mouseout: function() {
            // If product is preorder, display preorder, else display add to basket
            if ($("#preorderEclat").length) {
                btn_add_cart.val(Translator.translate('add_basket_preco'));
            } else {
                btn_add_cart.val(Translator.translate('add_basket'));
            }
            if ($(addCartWrapper).hasClass("inactiv")) {
                if (disabledBtnTooltip) {
                    disabledBtnTooltip.removeClass('actif');
                }
            }
        }
    });
}
eventButton();

$('.close_pop').on("click", function() {
    closeMultiShad('choose_size');
});

window.setTimeout(function() {
    $('#cookiesLightbox section h4').each(function() {
        var cookiesh4 = $(this);
        $(this).replaceWith('<p class="cookiesh4">' + cookiesh4.text() + '</p>');
    });

    $(".faq_questions .faq_question").wrapInner("<h3></h3>");

    $(".footer_legalmentions .row .content .content_inside ul li strong").wrapInner("<h3></h3>");

    $('.cms-page-module.cover-module .cover_module_container:not(#slider_cover_module_swiper_0) .cms-page-module-title').each(function() {
        var cms_page_module_remove_h1 = $(this);
        $(this).replaceWith('<p class="cms-page-module-title">' + cms_page_module_remove_h1.text() + '</p>');
    });

}, 500);

/* CMSPAGE */
window.setTimeout(function() {
    $(".cms_page #cms_page_title h1.title").remove();
    $(".cms_page_new #cms_page_title h1.title").remove();
}, 200);

// chargement video
window.setTimeout(function() {
    if ($(".category  .wrap_video").length) {
        $(".wrap_video").removeClass('imgLoaded');
    }
}, 750);

$(document).ready(function() {
    window.setTimeout(function() {
        if ($('.slider_cover_module_swiper .swiper-slide').length) {
            new Swiper('.cover_module_container.swiper-container', {
                slidesPerView: 1,
                speed: 1500,
                simulateTouch: true,
                autoplay: {
                    delay: 5000,
                },
            });
        }

        if ($('#cmsProductListSwiper_list_1 .swiper-wrapper li.swiper-slide').length > 4) {
            $('#cmsProductListSwiper_list_1 .swiper-wrapper').addClass('active');
            $('.cms_list_1_btn').addClass('active');
            $('.swiper-scrollbar').addClass('active');

            new Swiper('#cmsProductListSwiper_list_1', {
                slidesPerView: 4,
                spaceBetween: 10,
                navigation: {
                    nextEl: '.cms_list_1_btn.active.swiper-button-next',
                    prevEl: '.cms_list_1_btn.active.swiper-button-prev',
                },
                pagination: {
                  el: ".swiper-scrollbar.active",
                  type: "progressbar",
                },
                breakpoints: {
                    1360: {
                        slidesPerView: 3,
                    },
                    1023: {
                        slidesPerView: 2,
                    },
                },
            });
        }

        $('.products-list-2 [id^=cmsProductListSwiper_list]').each(function() {
            var nextEl_products_list_module_swiper = $(this).parent().find( '.cms_list_2_btn.swiper-button-next');
            var prevEl_products_list_module_swiper = $(this).parent().find( '.cms_list_2_btn.swiper-button-prev');

            if ($('.products-list-2 [id^=cmsProductListSwiper_list] .swiper-slide').length) {
                var products_list_module_swiper = new Swiper($(this), {
                    slidesPerView: 3,
                    spaceBetween: 14,
                    navigation: {
                        nextEl: nextEl_products_list_module_swiper,
                        prevEl: prevEl_products_list_module_swiper,
                    },
                    breakpoints: {
                        1260: {
                            slidesPerView: 2,
                        },
                        1023: {
                            slidesPerView: 2,
                        },
                    },
                });
            }
        });

        if ($('.look-look-1-left .look_module_swiper .swiper-slide').length) {
            new Swiper('.look-look-1-left .look_module_swiper', {
                slidesPerView: 1.15,
                loop: true,
                spaceBetween: 13,
                direction: "vertical",
                slidesOffsetBefore: 110,
                navigation: {
                    nextEl: '.look-look-1-left .look_button.swiper-button-next',
                    prevEl: '.look-look-1-left .look_button.swiper-button-prev',
                },
                speed: 6000,
                autoplay: {
                    delay: 10
                },
            });

            $(".look-look-1-left .look_module_swiper").each(function(elem, target){
                var swp = target.swiper;
                $(this).on('mouseenter', function(){
                    swp.autoplay.stop();
                });
                $(this).on('mouseleave', function(){
                     swp.autoplay.start();
                });
            });
        }

        if ($('.look-look-1-right .look_module_swiper .swiper-slide').length) {
            new Swiper('.look-look-1-right .look_module_swiper', {
                slidesPerView: 1.15,
                loop: true,
                spaceBetween: 13,
                direction: "vertical",
                navigation: {
                    nextEl: '.look-look-1-right .look_button.swiper-button-next',
                    prevEl: '.look-look-1-right .look_button.swiper-button-prev',
                },
                speed: 6000,
                autoplay: {
                    delay: 10
                },
            });

            $(".look-look-1-right .look_module_swiper").each(function(elem, target){
                var swp2 = target.swiper;
                $(this).on('mouseenter', function(){
                    swp2.autoplay.stop();
                });
                $(this).on('mouseleave', function(){
                     swp2.autoplay.start();
                });
            });
        }

        if ($('.look-look-2-image .look_module_swiper .swiper-slide').length) {
            new Swiper('.look-look-2-image .look_module_swiper', {
                slidesPerView: 1.15,
                loop: true,
                spaceBetween: 13,
                direction: "vertical",
                navigation: {
                    nextEl: '.look-look-2-image .look_button.swiper-button-next',
                    prevEl: '.look-look-2-image .look_button.swiper-button-prev',
                },
                speed: 6000,
                autoplay: {
                    delay: 10
                },
            });
        }

        $(".look-look-2-image .look_module_swiper").each(function(elem, target){
            var swp3 = target.swiper;
            $(this).on('mouseenter', function(){
                swp3.autoplay.stop();
            });
            $(this).on('mouseleave', function(){
                 swp3.autoplay.start();
            });
        });


    }, 650);

});


$(document).ready(function vertical_Swiper_height() {
    window.setTimeout(function() {
        var look_wrapper_height = $('.look-look .look_module_swiper .look_main_picture_container').height();
        var look_swiper_texte = $('.cms_page .cms_page_content>.cms-page-module.look-look .swiper_texte_wrapper .swiper_texte');
        var look_module_swiper = $('.cms_page .cms_page_content>.cms-page-module.look-look .look_module_swiper');

        look_module_swiper.css('max-height', look_wrapper_height * 1.2);
        look_swiper_texte.css('height', look_wrapper_height * 1.2);

        var look_swiper_texte_height = $('.cms_page .cms_page_content>.cms-page-module.look-look .swiper_texte_wrapper .swiper_texte .swiper-slide').height();

        if (look_swiper_texte_height > look_wrapper_height) {
            $('.swiper_texte_wrapper .swiper-button').addClass('enabled');
            $('.swiper_texte_wrapper .swiper-button').removeClass('disabled');
        } else {
            $('.swiper_texte_wrapper .swiper-button').removeClass('enabled');
            $('.swiper_texte_wrapper .swiper-button').addClass('disabled');
        }


    }, 150);
});

$("#scrollBy").click(function () {
  scrollBy('#contentToScroll', 100);
});

$("#scrollByNeg").click(function () {
    scrollBy('#contentToScroll', -100);
});

function scrollBy(el, amt){
    var elemToScroll = $(el);
    elemToScroll.scrollTop(elemToScroll.scrollTop() + amt);
}

/* END  CSM PAGE */

/* FP */

$(document).ready(function() {
    cartClearHTMLTag();

    // Fade in product cross selling swiper
    if($('#product_associations_bloc').length) {
        if($('#product_associations_bloc').hasClass('hide')) {
            $('#product_associations_bloc').removeClass('hide');
            $('#product_associations_bloc').addClass('show');
        } else {
            $('#product_associations_bloc').addClass('hide');
            $('#product_associations_bloc').removeClass('show');
        }
    }
});

/* Ajouter fonction zoom sur le zoom BTN */
$(function(){
    var fp_zoom_btn = $('body.product_page .fp_zoom_btn');

    fp_zoom_btn.click(function(){
        zoomImg();
        $('body.product_page').css('position','fixed');
     });

    initialise_tunnelSwiper();

    /* Ajout overlay scrollbar sur le product_feats_wrapper FP */

    var fp_product_feats_wrapper = $('body.product_page .product_feats_wrapper .thumbcnt .thumbslide');
    fp_product_feats_wrapper.overlayScrollbars({});

});

/* Swiper mes indispensables sur le panier */
function initialise_tunnelSwiper() {
	// Initialisation du slider si présent
    var tunnelswiperContainer = $('#cart_slider_wrap');
	if (tunnelswiperContainer) {
		setTimeout(function(){
            var tunnelSwiper = new Swiper('#cart_slider_wrap', {
				slidesPerView: 3,
				spaceBetween: 15,
                roundLengths: true,
				loop: false,
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev',
				},
                onSlideChangeStart: function(){
                    $('#cart_slider_wrap').width($(tunnelSwiper.activeSlide()).width())
                },
                pagination: {
                el: ".swiper-pagination",
                  type: "progressbar",
                },
                preloadImages: false,
                lazy: true,
                loadPrevNext: true,
                loadPrevNextAmount: 1,
                loadOnTransitionStart: true
			});
			$('#cart_slider_wrap').animate({opacity: 1},1000);
		}, 1000);
	}
}


/* JS empechant l'Ajax d'impacter les jquery sur le tunnel */

var tunnel_basket = $('body.cart .cart_main_table.basket');
tunnel_basket.bind('DOMSubtreeModified', function(e) {

    if (tunnel_basket.length) {
        /*Déplacer le prix produit dans la wishlist auprès du titre */
        window.setTimeout(function() {
            var product_mod = $('body.cart .cart_main_table.wishlist .product_mod .cart_product_title');
            var wrapper_cart_product_price = $('body.cart .cart_main_table.wishlist .wrapper_cart_product_price');

            $(wrapper_cart_product_price).appendTo(product_mod);

        }, 100);

        /* Déplacer pushwrapper */
        $('body.step_1 .pushBasketWrapper').insertBefore('body.step_1 .bloc_question').show(1000);

        /* Initialiser le swiper cart_slider_wrap sur le panier */
        initialise_tunnelSwiper();

        /* Initialiser le swiper productVisualMulti sur le panier */
        initialise_productSwiper();

        cartClearHTMLTag();
    }

});

function cartClearHTMLTag() {
    setTimeout(function(){
        var str = $( ".cart_main_title.wishlist span.nb_products" ).text();
        str = str.split(" ").join("");
        str = str.split("\n").join("");
        str = str.replace(Translator.translate('one_product'),' ' + Translator.translate('one_product'));
        str = str.replace(Translator.translate('2_product'),' ' + Translator.translate('2_product'));
        $( ".cart_main_title.wishlist span.nb_products" ).html( str );
    }, 800);
}

setTimeout(function(){
    if ($('.sub_categ #slideCateg .itm_name h1').length) {
        $('.body').addClass('sous_cat');
    } else {
        $('.category #breadcrumbs .breadcrumbs .breadcrumb.current span').each(function() {
            var breadcrumb_h1 = $(this);
            $(this).replaceWith('<span><h1>' + breadcrumb_h1.text() + '</h1></span>');
        });
    }
}, 1000);

//MODULE QUESTION - PAGE ADV
$(function(){
    $('.cms-page-module.offre-module').on('click', function() {
        if($(this).hasClass('active')) {
            $(this).removeClass('active');
            $(this).find('.cms-page-module-text').slideToggle();
        } else {
            $('.cms-page-module.offre-module').each(function() {
                $(this).removeClass('active');
                $(this).find('.cms-page-module-text').slideUp();
            });
            $(this).addClass('active');
            $(this).find('.cms-page-module-text').slideToggle();
        }
    });
});

// Activate the add to cart when the product has a unique size
function disableSizeCheckTU() {
    // check the value of the hidden input containing $cnt_sizes
    var sizeTU = $('#sizeType').val();
    //when $cnt_sizes = 1 it is TU
    if (sizeTU == 1) {
        enableAddCart();
    } else {
        // disable add to cart
        disableAddCart();
    }
}

$(function () {
    // Check if there is at least one element with class "products-list-1"
    if ($(".products-list-1").length) {
        // Loop through each element with class "products-list-1"
        $(".products-list-1").each(function () {
            // Check if the element has 1 child elements with id starting with "cms-page-colum-product-"
            if ($(this).children("[id^='cms-page-colum-product-']").length = 1) {
                $(this).children("[id^='cms-page-colum-product-']").addClass("one_product");
            }
        });
    }
});