function setSticky(waypointTarget, stickyElTarget) {
    $(window).on("scroll", function() {
        header = $('#site_head_wrap');
        scrollDistance = $(window).scrollTop();
        stickyWaypoint = $(waypointTarget).height();

        if (scrollDistance >= stickyWaypoint) {
            $(stickyElTarget).addClass('is-sticky');
            header.css('height', '0px');

            if ($('body').hasClass('product_page')) {
                $('.product_infos.not_achat_express').css('position', 'relative');
                $('.is-sticky #itm_name span').hide();
            }

            if ($('body').hasClass('step_1')) {
                $('.bill_line.total_produit label+.price').insertBefore($('#formPanier'));
            }
        } else {
            if ($('body').hasClass('product_page')) {
                // show span before removing is-sticky class
                $('.is-sticky #itm_name span').show();
            }
            $(stickyElTarget).removeClass('is-sticky');
            header.css('height', 'auto');

            if ($('body').hasClass('product_page')) {
                $('.product_infos.product_infos.not_achat_express').css('position', 'sticky');
            }

            if ($('body').hasClass('step_1')) {
                $('.formPanier_container>.price').insertAfter($('.bill_line.total_produit label'));
            }
        }
    });
}